// Heading.js

import React from 'react';
import './Heading.css';

const Heading = ({ text }) => {
  return (
    <div className="bg-green text-white text-center pt-1 pb-1" style={{ fontSize: '118%' }}>
      <span>
        <b>{text}</b>
      </span>
    </div>
  );
};

export default Heading;
