import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { postDeleteInformationOfSubmittedApplicationsOfViewUpdateApplicationForm } from "../../../../../api";

const DeleteFileModal = ({
  show,
  onHide,
  selectedDoc,
  pointerID,
  getAllDataOfSubmittedApplicationsFunction,
  stage,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {


    try {
      setLoading(true);

      const formData = new FormData();
        formData.append("document_id", selectedDoc?.document_id);
      formData.append("stage", stage);
      formData.append("pointer_id", pointerID);

      const response = await postDeleteInformationOfSubmittedApplicationsOfViewUpdateApplicationForm(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Deleted Successfully."
        );

        getAllDataOfSubmittedApplicationsFunction(pointerID);
        onHide();
      } else {
        setLoading(false);
        console.error(
          "Error in Saving",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.error_msg || "Error in Deleting");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Deleted", error);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={show}
        onHide={onHide}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            Delete {selectedDoc?.document_name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {`Are you sure that you would like to delete `}
            <span style={{ fontWeight: "bold", color: "red" }}>
              {selectedDoc?.document_name}
            </span>
            {` file permanently?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-danger ms-1"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onHide}
          >
            No
          </Button>
          <Button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={handleDelete}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteFileModal;
