import React, { useState, useEffect } from "react";
import ReferralFees from "./Accounting/Referral Fees";
import OffshoresFees from "./Accounting/Offshores Fees";

const Accounting = () => {
  const [ReferralTabActive, setReferralTabActive] = useState(false);
  const [OffshoresTabActive, setOffshoresTabActive] = useState(false);

  useEffect(() => {
    // Load active tab state from local storage on initial render
    if (localStorage.getItem("accounting_referral_tab_active") === "true") {
      setReferralTabActive(true);
      setOffshoresTabActive(false);
    } else if (localStorage.getItem("accounting_offshores_tab_active") === "true") {
      setOffshoresTabActive(true);
      setReferralTabActive(false);
    } else {
      // Default to Referral tab if neither state is set in local storage
      setReferralTabActive(true);
      setOffshoresTabActive(false);
    }
  }, []);

  const handleReferral = () => {
    setReferralTabActive(true);
    setOffshoresTabActive(false);
    // Update local storage
    localStorage.setItem("accounting_referral_tab_active", "true");
    localStorage.setItem("accounting_offshores_tab_active", "false");
  };

  const handleOffshores = () => {
    setOffshoresTabActive(true);
    setReferralTabActive(false);
    // Update local storage
    localStorage.setItem("accounting_referral_tab_active", "false");
    localStorage.setItem("accounting_offshores_tab_active", "true");
  };

  return (
    <>
      <div className="col-12 accup">
        <button
          onClick={handleReferral}
          className={`btn ${ReferralTabActive ? "btn_green_yellow" : "btn1_yellow_green"} mr-2`}
        >
          Referral Fees
        </button>
        <button
          onClick={handleOffshores}
          className={`btn ${OffshoresTabActive ? "btn_green_yellow" : "btn1_yellow_green"}`}
        >
          Offshores Fees
        </button>
      </div>

      <hr />
      {/* Render the selected tab content */}
      {ReferralTabActive && <ReferralFees />}
      {OffshoresTabActive && <OffshoresFees />}
    </>
  );
};

export default Accounting;
