import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import './ApplicantTransfer.css';

import TomSelectComponent from "../../../Custom Componets/Tom/TomSelectComponent";

import {
  getDataForTransferApplicationForm,
  postDataForTransferApplicationForm,
} from "../../../../api";

import SidebarMenu from "../../../Widgets/SideBar Menu/SidebarMenu";
import Loader from "../../../Widgets/Loader/Loader";

const ApplicantTransfer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const { pointerID } = location.state || {};

  const [CurrentPageData, setCurrentPageData] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  const [agentApplicantOptions, setAgentApplicantOptions] = useState([]);

  // State for file and errors
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileErrors, setFileErrors] = useState("");
  const fileInputRef = useRef(null);

  const getApplicationManagerFunction = async () => {
    try {
      setLoading(true);

      const response = await getDataForTransferApplicationForm(pointerID);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setCurrentPageData(response?.data?.response?.data);
        setAgentApplicantOptions(response?.data?.response?.data?.to_dropdown);

        setSelectedOption("");
        setSelectedFile("");
      } else {
        setLoading(false);
        console.error(
          "Error Fetching Records",
          response?.data?.error_msg || "Unknown error"
        );
        // toast.error(
        //   response?.data?.error_msg || "Error Fetching Records" )
      }
    } catch (error) {
      setLoading(false);
      console.error("Error Fetching Records", error);
    }
  };

  useEffect(() => {
    getApplicationManagerFunction(pointerID);
  }, []);

  const handleBack = (event) => {
    event.preventDefault();
    // navigate("/admin/application_manager/view_application", {
    //   state: { pointerID: pointerID },
    // });

    navigate(`/admin/application_manager/view_application?pointerID=${pointerID}`);

  };


  const handleSelectAgentApplicant = (value) => {
    console.log(value);
    setSelectedOption(value);
  };

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check file type
      if (file.type !== "application/pdf") {
        setFileErrors("Only PDF files are allowed.");
        setSelectedFile(null);
        fileInputRef.current.value = ""; // Clear the input
      } else {
        setFileErrors("");
        setSelectedFile(file);
      }
    }
  };

  const handleNext = async (event) => {
    event.preventDefault();

    if (!selectedOption) {
      toast.error("Please select an Agent or Applicant.");
      return;
    }

    if (!selectedFile) {
      toast.error("Please choose a PDF file for Agent Authorisation Form.");
      return;
    }

    // navigate(
    //   "/admin/application_manager/view_application/transfer_applicant/preview",
    //   {
    //     state: { pointerID: pointerID },
    //   }
    // );

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("new_agent", selectedOption);
      formData.append("file", selectedFile);
      formData.append("pointer_id", pointerID);

      const response = await postDataForTransferApplicationForm(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );

        navigate(
          "/admin/application_manager/view_application/transfer_applicant/preview",
          {
            state: { pointerID: pointerID, selectedOption: selectedOption },
          }
        );
      } else {
        setLoading(false);
        console.error(
          "Error in Saving",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.error_msg || "Error in Saving");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Saving", error);
    }
  };




  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleFocus = () => setIsDropdownOpen(true); // Dropdown is likely open
  const handleBlur = () => setIsDropdownOpen(false); // Dropdown is likely closed


  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        {loading && <Loader />}

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Application Transfer
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                <Card.Body>
                  {CurrentPageData?.length === 0 && !loading && (
                    <div className="no-data-message">
                      <p className="oops-message">Oops! No data to display.</p>
                    </div>
                  )}

                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div>
                      <table
                        className="table table-striped table-bordered w-100"
                        style={{
                          borderRadius: "10px",
                          overflow: "hidden",
                          borderCollapse: "separate",
                          borderSpacing: 0,
                          border: "0.5px solid #fecc00",
                          cursor: "default",
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              colSpan={3}
                              style={{
                                textAlign: "center",
                                backgroundColor: "#f8f9fa",
                              }}
                            >
                              Applicant's Information
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ width: "50%", padding: "8px" }}>
                              Applicant's Name
                            </td>
                            <td style={{ width: "50%", padding: "8px" }}>
                              {CurrentPageData?.applicant_name}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "50%", padding: "8px" }}>
                              Email
                            </td>
                            <td style={{ width: "50%", padding: "8px" }}>
                              {CurrentPageData?.applicant_email}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "50%", padding: "8px" }}>
                              Contact Number
                            </td>
                            <td style={{ width: "50%", padding: "8px" }}>
                              + {CurrentPageData?.applicant_monile_no_code}{" "}
                              {CurrentPageData?.applicant_monile_no}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "50%", padding: "8px" }}>
                              Occupation
                            </td>
                            <td style={{ width: "50%", padding: "8px" }}>
                              {CurrentPageData?.applicant_occupation}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "50%", padding: "8px" }}>
                              Program
                            </td>
                            <td style={{ width: "50%", padding: "8px" }}>
                              {CurrentPageData?.applicant_program}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "50%", padding: "8px" }}>
                              Pathway
                            </td>
                            <td style={{ width: "50%", padding: "8px" }}>
                              {CurrentPageData?.applicant_pathway}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <Row>
                        <Col md={6}>
                          <div>
                            <table
                              className="table table-striped table-bordered w-100"
                              style={{
                                borderRadius: "10px",
                                overflow: "hidden",
                                borderCollapse: "separate",
                                borderSpacing: 0,
                                border: "0.5px solid #fecc00",
                                cursor: "default",
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan={2}
                                    style={{
                                      textAlign: "center",
                                      backgroundColor: "#f8f9fa",
                                    }}
                                  >
                                    From :
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ width: "50%", padding: "8px" }}>
                                    Name
                                  </td>
                                  <td style={{ width: "50%", padding: "8px" }}>
                                    {CurrentPageData?.user_ac_name}
                                  </td>
                                </tr>
                                {CurrentPageData?.user_ac_business_name && (
                                  <tr>
                                    <td
                                      style={{ width: "50%", padding: "8px" }}
                                    >
                                      User Business Name
                                    </td>
                                    <td
                                      style={{ width: "50%", padding: "8px" }}
                                    >
                                      {CurrentPageData?.user_ac_business_name}
                                    </td>
                                  </tr>
                                )}

                                <tr>
                                  <td style={{ width: "50%", padding: "8px" }}>
                                    Email
                                  </td>
                                  <td style={{ width: "50%", padding: "8px" }}>
                                    {CurrentPageData?.user_ac_email}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "50%", padding: "8px" }}>
                                    Account Type
                                  </td>
                                  <td style={{ width: "50%", padding: "8px" }}>
                                    {CurrentPageData?.user_account_type}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div>
                            <table
                              className="table table-striped table-bordered w-100"
                              style={{
                                borderRadius: "10px",
                                overflow: "hidden",
                                borderCollapse: "separate",
                                borderSpacing: 0,
                                border: "0.5px solid #fecc00",
                                cursor: "default",
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan={2}
                                    style={{
                                      textAlign: "center",
                                      backgroundColor: "#f8f9fa",
                                    }}
                                  >
                                    To :
                                  </th>
                                </tr>
                              </thead>
                              <tbody >















                                {/* <tr  
                                >
                                  <td >
                                    <Form.Group controlId="occupationSelect">

                                      <TomSelectComponent
                                        options={agentApplicantOptions.map(a => ({
                                          value: a.user_id,
                                          label: a.dropdown_name
                                        }))}
                                        onChange={handleSelectAgentApplicant}
                                        placeholder="Select Agent/Applicant"
                                        value={selectedOption}
                                        plugins={['dropdown_input']}
                                      />
                                    </Form.Group>
                                  
                                  </td>
                                </tr> */}



<tr style={{ position: "relative", zIndex: isDropdownOpen ? 1050000 : 1 }}>
      <td>
        <Form.Group controlId="occupationSelect">
          <div style={{ position: "relative", zIndex: isDropdownOpen ? 1050000 : 1 }}>
            <TomSelectComponent
              options={agentApplicantOptions.map(a => ({
                value: a.user_id,
                label: a.dropdown_name,
              }))}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleSelectAgentApplicant}
              placeholder="Select Agent/Applicant"
              value={selectedOption}
              plugins={["dropdown_input"]}
            />
          </div>
        </Form.Group>
      </td>
    </tr>





                                <tr>
                                  <td
                                    style={{
                                      width: "100%",
                                      padding: "8px",
                                      fontWeight: "bold",
                                      color: "#055837",
                                    }}
                                  >
                                    Agent Authorisation Form -
                                  </td>
                                </tr>

                                <tr>
                                  <td style={{ width: "100%", padding: "8px" }}>
                                    <input
                                      type="file"
                                      accept=".pdf"
                                      className="form-control"
                                      ref={fileInputRef}
                                      onChange={handleFileChange}
                                      style={{ height: "35px" }}
                                    />
                                    {fileErrors && (
                                      <div
                                        className="text-danger"
                                        style={{ marginTop: "8px" }}
                                      >
                                        {fileErrors}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <hr className="mb-1 thick-hr" />

                    <div
                      className="button-group text-center mb-0"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#ffcc01",
                          color: "#055837",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onClick={handleBack}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNext}
                        style={{
                          backgroundColor: "#055837",
                          color: "#ffcc01",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicantTransfer;
