// CommonModules.js

import React from "react";
import "./Common Modules.css";
import { FaSignInAlt, FaTimes } from "react-icons/fa";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from "react-router-dom";


const LoginButton = ({ onClick, buttonText, icon }) => {
  return (
    <div className="login-btn-container">
      <button className="btn login-button" type="button" onClick={onClick}>
        {icon && <FaSignInAlt style={{ fontWeight: 'normal' }} />}
        {buttonText}
      </button>
    </div>
  );
};


  
  const CancelButton = ({ onClick }) => {
    return (
      <div className="cancel-btn-container">
        <button className="btn cancel-button" type="button" onClick={onClick}>
          <FaTimes style={{ fontWeight: 'normal' }} />
          Cancel
        </button>
      </div>
    );
  };
  

  const AccountLink = ({ linkText, linkUrl, promptText }) => {
    return (
      <div className="col-12 account-link">
        <p className="account-link">
          {promptText}{" "}
          <Link to={linkUrl} className="text-green">
            <b>{linkText}</b>
          </Link>
        </p>
        {/* <div style={{ marginBottom: "10px" }}></div> */}
      </div>
    );
  };

const CommonModules = () => {
  const handleLoginClick = () => {
    console.log("");
  };

  const handleCancelClick = () => {
    console.log("");
  };

  const handleLinkText = () => {
    console.log("");
  };

  const handleLinkURL = () => {
    console.log("");
  };
  const handlePromptText = () => {
    console.log("");
  };


  return (
    <>


      <LoginButton onClick={handleLoginClick} />
      <CancelButton onClick={handleCancelClick} />

      <AccountLink
        linkText={handleLinkText}
        linkUrl={handleLinkURL}
        promptText={handlePromptText}
      />


    </>
  );
};

export { LoginButton, CancelButton, AccountLink };
export default CommonModules;
