import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getLocationIndividualAPI,
  updateLocationIndividualAPI,
} from "../../api";
import Loader from "../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InterviewLocationChange = ({
  showModal,
  handleClose,
  selectedItem,
  setSelectedItem,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  const [formValues, setFormValues] = useState({
    id: null,
    city_name: "",
    country: "",
    venue: "",
    email: "",
    email_cc: "",
    office_address: "",
    contact_details: "",
    cost: "",
  });

  const getInterviewInfoFunction = async () => {
    try {
      setLoading(true);
      const response = await getLocationIndividualAPI(selectedItem);
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true &&
        response.data.response.data
      ) {
        const individualData = response.data.response.data;
        setOriginalData(individualData);
      } else {
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching occupations data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      getInterviewInfoFunction();
    }
  }, [selectedItem]);

  useEffect(() => {
    if (originalData.length > 0) {
      setFormValues({
        id: selectedItem,
        city_name: originalData[0]?.city_name || "",
        country: originalData[0]?.country || "",
        venue: originalData[0]?.venue || "",
        email: originalData[0]?.email || "",
        email_cc: originalData[0]?.email_cc || "",
        office_address: originalData[0]?.office_address || "",
        contact_details: originalData[0]?.contact_details || "",
        cost: originalData[0]?.cost || "",
      });
    }
  }, [originalData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      for (const key in formValues) {
        formData.append(key, formValues[key]);
      }

      const response = await updateLocationIndividualAPI(formData);
      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        console.log("Update successful:", response.data.response.data);
        toast.success(
          response?.data?.response?.success_msg || "Updated Successfully."
        );
        refreshData();
        setSelectedItem(null);
        handleClose();
      } else {
        console.error(
          "Error updating location data:",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in updating."
        );
      }
    } catch (error) {
      console.error("Error updating location data:", error);
    } finally {
      setLoading(false);
      setSelectedItem(null);
      handleClose();
    }
  };

  const closeModel = () => {
    setSelectedItem(null);
    handleClose();
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={showModal}
        onHide={handleClose}
        className="model_mannual"
        centered
      >
        <div className="modal-content" style={{ backgroundColor: "white" }}>
          <div className="modal-header">
            <h5 className="modal-title text-center text-green">
              Interview Location Change
            </h5>
          </div>
          <div className="modal-body">
            <form
              className="edit_data"
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <div className="mb-3 row">
                <label htmlFor="Name" className="col-sm-2">
                  <strong>City</strong> <span className="text-danger">*</span>
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="city_name"
                    value={formValues.city_name}
                    className="form-control"
                    onChange={handleInputChange}
                    id="Name"
                    required
                  />
                  <input type="hidden" name="id" value={formValues.id} />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="country" className="col-sm-2">
                  <strong>Country</strong>{" "}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-sm-10">
                  <select
                    className="form-select"
                    name="country"
                    value={formValues?.country}
                    id="country"
                    onChange={handleInputChange}
                    aria-label="Default select example"
                    required
                    style={{ cursor: "pointer" }}
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>

                    <option value="Antigua And Barbuda">
                      {" "}
                      Antigua And Barbuda{" "}
                    </option>
                    <option value="Argentina"> Argentina </option>
                    <option value="Armenia"> Armenia </option>
                    <option value="Aruba"> Aruba </option>
                    <option value="Australia" selected="">
                      {" "}
                      Australia{" "}
                    </option>
                    <option value="Austria"> Austria </option>
                    <option value="Azerbaijan"> Azerbaijan </option>
                    <option value="Bahamas The"> Bahamas The </option>
                    <option value="Bahrain"> Bahrain </option>
                    <option value="Bangladesh"> Bangladesh </option>
                    <option value="Barbados"> Barbados </option>
                    <option value="Belarus"> Belarus </option>
                    <option value="Belgium"> Belgium </option>
                    <option value="Belize"> Belize </option>
                    <option value="Benin"> Benin </option>
                    <option value="Bermuda"> Bermuda </option>
                    <option value="Bhutan"> Bhutan </option>
                    <option value="Bolivia"> Bolivia </option>
                    <option value="Bosnia and Herzegovina">
                      {" "}
                      Bosnia and Herzegovina{" "}
                    </option>
                    <option value="Botswana"> Botswana </option>
                    <option value="Bouvet Island"> Bouvet Island </option>
                    <option value="Brazil"> Brazil </option>
                    <option value="British Indian Ocean Territory">
                      {" "}
                      British Indian Ocean Territory{" "}
                    </option>
                    <option value="Brunei"> Brunei </option>
                    <option value="Bulgaria"> Bulgaria </option>
                    <option value="Burkina Faso"> Burkina Faso </option>
                    <option value="Burundi"> Burundi </option>
                    <option value="Cambodia"> Cambodia </option>
                    <option value="Cameroon"> Cameroon </option>
                    <option value="Canada"> Canada </option>
                    <option value="Cape Verde"> Cape Verde </option>
                    <option value="Cayman Islands"> Cayman Islands </option>
                    <option value="Central African Republic">
                      {" "}
                      Central African Republic{" "}
                    </option>
                    <option value="Chad"> Chad </option>
                    <option value="Chile"> Chile </option>
                    <option value="China"> China </option>
                    <option value="Cocos (Keeling) Islands">
                      {" "}
                      Cocos (Keeling) Islands{" "}
                    </option>
                    <option value="Colombia"> Colombia </option>
                    <option value="Comoros"> Comoros </option>
                    <option value="Republic Of The Congo">
                      {" "}
                      Republic Of The Congo{" "}
                    </option>
                    <option value="Democratic Republic Of The Congo">
                      {" "}
                      Democratic Republic Of The Congo{" "}
                    </option>
                    <option value="Cook Islands"> Cook Islands </option>
                    <option value="Costa Rica"> Costa Rica </option>
                    <option value="Cote D'Ivoire (Ivory Coast)">
                      {" "}
                      Cote D'Ivoire (Ivory Coast){" "}
                    </option>
                    <option value="Croatia (Hrvatska)">
                      {" "}
                      Croatia (Hrvatska){" "}
                    </option>
                    <option value="Cuba"> Cuba </option>
                    <option value="Cyprus"> Cyprus </option>
                    <option value="Czech Republic"> Czech Republic </option>
                    <option value="Denmark"> Denmark </option>
                    <option value="Djibouti"> Djibouti </option>
                    <option value="Dominica"> Dominica </option>
                    <option value="Dominican Republic">
                      {" "}
                      Dominican Republic{" "}
                    </option>
                    <option value="East Timor"> East Timor </option>
                    <option value="Ecuador"> Ecuador </option>
                    <option value="Egypt"> Egypt </option>
                    <option value="El Salvador"> El Salvador </option>
                    <option value="Equatorial Guinea">
                      {" "}
                      Equatorial Guinea{" "}
                    </option>
                    <option value="Eritrea"> Eritrea </option>
                    <option value="Estonia"> Estonia </option>
                    <option value="Ethiopia"> Ethiopia </option>
                    <option value="Falkland Islands"> Falkland Islands </option>
                    <option value="Faroe Islands"> Faroe Islands </option>
                    <option value="Fiji Islands"> Fiji Islands </option>
                    <option value="Finland"> Finland </option>
                    <option value="France"> France </option>
                    <option value="French Guiana"> French Guiana </option>
                    <option value="French Polynesia"> French Polynesia </option>
                    <option value="French Southern Territories">
                      {" "}
                      French Southern Territories{" "}
                    </option>
                    <option value="Gabon"> Gabon </option>
                    <option value="Gambia The"> Gambia The </option>
                    <option value="Georgia"> Georgia </option>
                    <option value="Germany"> Germany </option>
                    <option value="Ghana"> Ghana </option>
                    <option value="Gibraltar"> Gibraltar </option>
                    <option value="Greece"> Greece </option>
                    <option value="Greenland"> Greenland </option>
                    <option value="Grenada"> Grenada </option>
                    <option value="Guadeloupe"> Guadeloupe </option>
                    <option value="Guam"> Guam </option>
                    <option value="Guatemala"> Guatemala </option>
                    <option value="Guernsey and Alderney">
                      {" "}
                      Guernsey and Alderney{" "}
                    </option>
                    <option value="Guinea"> Guinea </option>
                    <option value="Guinea-Bissau"> Guinea-Bissau </option>
                    <option value="Guyana"> Guyana </option>
                    <option value="Haiti"> Haiti </option>
                    <option value="Heard and McDonald Islands">
                      {" "}
                      Heard and McDonald Islands{" "}
                    </option>
                    <option value="Honduras"> Honduras </option>
                    <option value="Hong Kong S.A.R."> Hong Kong S.A.R. </option>
                    <option value="Hungary"> Hungary </option>
                    <option value="Iceland"> Iceland </option>
                    <option value="India"> India </option>
                    <option value="Indonesia"> Indonesia </option>
                    <option value="Iran"> Iran </option>
                    <option value="Iraq"> Iraq </option>
                    <option value="Ireland"> Ireland </option>
                    <option value="Israel"> Israel </option>
                    <option value="Italy"> Italy </option>
                    <option value="Jamaica"> Jamaica </option>
                    <option value="Japan"> Japan </option>
                    <option value="Jersey"> Jersey </option>
                    <option value="Jordan"> Jordan </option>
                    <option value="Kazakhstan"> Kazakhstan </option>
                    <option value="Kenya"> Kenya </option>
                    <option value="Kiribati"> Kiribati </option>
                    <option value="Korea North"> Korea North </option>
                    <option value="Korea South"> Korea South </option>
                    <option value="Kuwait"> Kuwait </option>
                    <option value="Kyrgyzstan"> Kyrgyzstan </option>
                    <option value="Laos"> Laos </option>
                    <option value="Latvia"> Latvia </option>
                    <option value="Lebanon"> Lebanon </option>
                    <option value="Lesotho"> Lesotho </option>
                    <option value="Liberia"> Liberia </option>
                    <option value="Libya"> Libya </option>
                    <option value="Liechtenstein"> Liechtenstein </option>
                    <option value="Lithuania"> Lithuania </option>
                    <option value="Luxembourg"> Luxembourg </option>
                    <option value="Macau S.A.R."> Macau S.A.R. </option>
                    <option value="Macedonia"> Macedonia </option>
                    <option value="Madagascar"> Madagascar </option>
                    <option value="Malawi"> Malawi </option>
                    <option value="Malaysia"> Malaysia </option>
                    <option value="Maldives"> Maldives </option>
                    <option value="Mali"> Mali </option>
                    <option value="Malta"> Malta </option>
                    <option value="Marshall Islands"> Marshall Islands </option>
                    <option value="Martinique"> Martinique </option>
                    <option value="Mauritania"> Mauritania </option>
                    <option value="Mauritius"> Mauritius </option>
                    <option value="Mayotte"> Mayotte </option>
                    <option value="Mexico"> Mexico </option>
                    <option value="Micronesia"> Micronesia </option>
                    <option value="Moldova"> Moldova </option>
                    <option value="Monaco"> Monaco </option>
                    <option value="Mongolia"> Mongolia </option>
                    <option value="Montserrat"> Montserrat </option>
                    <option value="Morocco"> Morocco </option>
                    <option value="Mozambique"> Mozambique </option>
                    <option value="Myanmar"> Myanmar </option>
                    <option value="Namibia"> Namibia </option>
                    <option value="Nauru"> Nauru </option>
                    <option value="Nepal"> Nepal </option>
                    <option value="Netherlands Antilles">
                      {" "}
                      Netherlands Antilles{" "}
                    </option>
                    <option value="Netherlands The"> Netherlands The </option>
                    <option value="New Caledonia"> New Caledonia </option>
                    <option value="New Zealand"> New Zealand </option>
                    <option value="Nicaragua"> Nicaragua </option>
                    <option value="Niger"> Niger </option>
                    <option value="Nigeria"> Nigeria </option>
                    <option value="Niue"> Niue </option>
                    <option value="Norfolk Island"> Norfolk Island </option>
                    <option value="Northern Mariana Islands">
                      {" "}
                      Northern Mariana Islands{" "}
                    </option>
                    <option value="Norway"> Norway </option>
                    <option value="Oman"> Oman </option>
                    <option value="Pakistan"> Pakistan </option>
                    <option value="Palau"> Palau </option>
                    <option value="Palestinian Territory Occupied">
                      {" "}
                      Palestinian Territory Occupied{" "}
                    </option>
                    <option value="Panama"> Panama </option>
                    <option value="Papua new Guinea"> Papua new Guinea </option>
                    <option value="Paraguay"> Paraguay </option>
                    <option value="Peru"> Peru </option>
                    <option value="Philippines"> Philippines </option>
                    <option value="Pitcairn Island"> Pitcairn Island </option>
                    <option value="Poland"> Poland </option>
                    <option value="Portugal"> Portugal </option>
                    <option value="Puerto Rico"> Puerto Rico </option>
                    <option value="Qatar"> Qatar </option>
                    <option value="Reunion"> Reunion </option>
                    <option value="Romania"> Romania </option>
                    <option value="Russia"> Russia </option>
                    <option value="Rwanda"> Rwanda </option>
                    <option value="Saint Helena"> Saint Helena </option>
                    <option value="Saint Kitts And Nevis">
                      {" "}
                      Saint Kitts And Nevis{" "}
                    </option>
                    <option value="Saint Lucia"> Saint Lucia </option>
                    <option value="Saint Pierre and Miquelon">
                      {" "}
                      Saint Pierre and Miquelon{" "}
                    </option>
                    <option value="Saint Vincent And The Grenadines">
                      {" "}
                      Saint Vincent And The Grenadines{" "}
                    </option>
                    <option value="Samoa"> Samoa </option>
                    <option value="San Marino"> San Marino </option>
                    <option value="Sao Tome and Principe">
                      {" "}
                      Sao Tome and Principe{" "}
                    </option>
                    <option value="Saudi Arabia"> Saudi Arabia </option>
                    <option value="Senegal"> Senegal </option>
                    <option value="Serbia"> Serbia </option>
                    <option value="Seychelles"> Seychelles </option>
                    <option value="Sierra Leone"> Sierra Leone </option>
                    <option value="Singapore"> Singapore </option>
                    <option value="Slovakia"> Slovakia </option>
                    <option value="Slovenia"> Slovenia </option>
                    <option value="Solomon Islands"> Solomon Islands </option>
                    <option value="Somalia"> Somalia </option>
                    <option value="South Africa"> South Africa </option>
                    <option value="South Georgia"> South Georgia </option>
                    <option value="South Sudan"> South Sudan </option>
                    <option value="Spain"> Spain </option>
                    <option value="Sri Lanka"> Sri Lanka </option>
                    <option value="Sudan"> Sudan </option>
                    <option value="Suriname"> Suriname </option>
                    <option value="Swaziland"> Swaziland </option>
                    <option value="Sweden"> Sweden </option>
                    <option value="Switzerland"> Switzerland </option>
                    <option value="Syria"> Syria </option>
                    <option value="Taiwan"> Taiwan </option>
                    <option value="Tajikistan"> Tajikistan </option>
                    <option value="Tanzania"> Tanzania </option>
                    <option value="Thailand"> Thailand </option>
                    <option value="Togo"> Togo </option>
                    <option value="Tokelau"> Tokelau </option>
                    <option value="Tonga"> Tonga </option>
                    <option value="Trinidad And Tobago">
                      {" "}
                      Trinidad And Tobago{" "}
                    </option>
                    <option value="Tunisia"> Tunisia </option>
                    <option value="Turkey"> Turkey </option>
                    <option value="Turkmenistan"> Turkmenistan </option>
                    <option value="Turks And Caicos Islands">
                      {" "}
                      Turks And Caicos Islands{" "}
                    </option>
                    <option value="Tuvalu"> Tuvalu </option>
                    <option value="Uganda"> Uganda </option>
                    <option value="Ukraine"> Ukraine </option>
                    <option value="United Arab Emirates">
                      {" "}
                      United Arab Emirates{" "}
                    </option>
                    <option value="United Kingdom"> United Kingdom </option>
                    <option value="United States"> United States </option>
                    <option value="United States Minor Outlying Islands">
                      {" "}
                      United States Minor Outlying Islands{" "}
                    </option>
                    <option value="Uruguay"> Uruguay </option>
                    <option value="Uzbekistan"> Uzbekistan </option>
                    <option value="Vanuatu"> Vanuatu </option>
                    <option value="Vatican City State (Holy See)">
                      {" "}
                      Vatican City State (Holy See){" "}
                    </option>
                    <option value="Venezuela"> Venezuela </option>
                    <option value="Vietnam"> Vietnam </option>
                    <option value="Virgin Islands (British)">
                      {" "}
                      Virgin Islands (British){" "}
                    </option>
                    <option value="Virgin Islands (US)">
                      {" "}
                      Virgin Islands (US){" "}
                    </option>
                    <option value="Wallis And Futuna Islands">
                      {" "}
                      Wallis And Futuna Islands{" "}
                    </option>
                    <option value="Western Sahara"> Western Sahara </option>
                    <option value="Yemen"> Yemen </option>
                    <option value="Yugoslavia"> Yugoslavia </option>
                    <option value="Zambia"> Zambia </option>
                    <option value="Zimbabwe"> Zimbabwe </option>
                    {/* Add other country options here */}
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="venue" className="col-sm-2">
                  <strong>Venue</strong> <span className="text-danger">*</span>
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="venue"
                    onChange={handleInputChange}
                    value={formValues.venue}
                    className="form-control"
                    id="venue"
                    required
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="email" className="col-sm-2">
                  <strong>Email</strong> <span className="text-danger">*</span>
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="email"
                    onChange={handleInputChange}
                    value={formValues.email}
                    className="form-control"
                    id="email"
                    required
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="email_cc" className="col-sm-2">
                  <strong>CC - Email ID </strong>
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="email_cc"
                    id="email_cc"
                    onChange={handleInputChange}
                    value={formValues.email_cc}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="office_address" className="col-sm-2">
                  <strong>Address</strong>{" "}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-sm-10">
                  <textarea
                    className="form-control"
                    name="office_address"
                    id="office_address"
                    onChange={handleInputChange}
                    value={formValues.office_address}
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="contact_details" className="col-sm-2">
                  <strong>Contact Details</strong>
                </label>
                <div className="col-sm-10">
                  <textarea
                    className="form-control"
                    name="contact_details"
                    id="contact_details"
                    onChange={handleInputChange}
                    value={formValues.contact_details}
                    rows="3"
                  ></textarea>
                </div>
              </div>

              {accountType === "super_admin" && (
                <>
                  {formValues.venue === "AQATO" && (
                    <div className="mb-3 row">
                      <label htmlFor="cost" className="col-sm-2">
                        <strong>Cost</strong>
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          className="form-control"
                          name="cost"
                          id="cost"
                          onChange={handleInputChange}
                          value={formValues?.cost}
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="row">
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn_yellow_green mr-1"
                    data-bs-dismiss="modal"
                    onClick={closeModel}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn_green_yellow">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InterviewLocationChange;
