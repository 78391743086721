import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import Employment from "./Employment/Employment";
import Qualification from "./Qualification/Qualification";

const Verification = () => {




  const [EmploymentTabActive, setEmploymentTabActive] = useState(false);
  const [QualificationTabActive, setQualificationTabActive] = useState(false);



  useEffect(() => {
    if (localStorage.getItem(`verification_employment_tab_active`) === "true") {
      setEmploymentTabActive(true);
      setQualificationTabActive(false);
    } else if (
      localStorage.getItem(`verification_qualification_tab_active`) === "true"
    ) {
      setQualificationTabActive(true);
      setEmploymentTabActive(false);
    } else {
      setEmploymentTabActive(true);
      setQualificationTabActive(false);
    }
  }, []);



  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Verification
              </h3>
            </div>

            <div className="card card-primary card-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    style={{ marginTop: "0px", cursor: "pointer" }}
                  >
                    <a
                      className={`nav-link nav_inactive_tab_btn ${
                        EmploymentTabActive ? "nav_active_tab_btn" : ""
                      }`}
                      id="tabs_view_edit"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_view_edit"
                      aria-selected={EmploymentTabActive ? "true" : "false"}
                      onClick={() => {
                        setEmploymentTabActive(true);
                        setQualificationTabActive(false);

                        localStorage.setItem(
                          `verification_employment_tab_active`,
                          "true"
                        );
                        localStorage.setItem(
                          `verification_qualification_tab_active`,
                          "false"
                        );
                      }}
                    >
                      <h6 className="h6">Employment</h6>
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      className={`nav-link nav_inactive_tab_btn ${
                        QualificationTabActive ? "nav_active_tab_btn" : ""
                      }`}
                      id="tabs_documents"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_documents"
                      aria-selected={QualificationTabActive ? "true" : "false"}
                      onClick={() => {
                        setQualificationTabActive(true);
                        setEmploymentTabActive(false);

                        localStorage.setItem(
                          `verification_employment_tab_active`,
                          "false"
                        );
                        localStorage.setItem(
                          `verification_qualification_tab_active`,
                          "true"
                        );
                      }}
                    >
                      <h6 className="h6">Qualification</h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {EmploymentTabActive && <Employment />}
            {QualificationTabActive && <Qualification />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
