import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getNoteRecordsOfApplicationManager_API,
  deleteSingleCommentNoteOfApplicationManager_API,
  deleteCompleteCommentOfApplicationManager_API,
  SaveSingleCommentNoteOfApplicationManager_API,
  deleteStageCommentOfApplicationManager_API,
} from "../../api";
import Loader from "../Widgets/Loader/Loader";

const AdditionalImportNotePopup = ({
  show,
  onClose,
  selectedItem,
  ApplicationManagerFetch,
}) => {
  const [loading, setLoading] = useState(false);
  const pointerID = selectedItem?.id;

  const [comments, setComments] = useState({});

  const [showCommentsInputs, setShowCommentsInputs] = useState({});

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [WholeApiData, setWholeApiData] = useState({});

  const [choosedSingleItem, setchoosedSingleItem] = useState(null);
  const [IsChoosedSingleItem, setIsChoosedSingleItem] = useState(false);

  const [ChoosedStageToDelete, setChoosedStageToDelete] = useState(null);
  const [IsChoosedStageToDelete, setIsChoosedStageToDelete] = useState(false);

  const handleDeleteRequest = (item) => {
    console.log("WEUIHFEW65WEHYUIBYFY", item);

    setShowConfirmModal(true);
    setchoosedSingleItem(item);
    if (item !== null) {
      setIsChoosedSingleItem(true);
    } else {
      setIsChoosedSingleItem(false);
    }
    setChoosedStageToDelete(null);
    setIsChoosedStageToDelete(false);
  };

  const handleDeleteStageRequest = (stage) => {
    setShowConfirmModal(true);
    setChoosedStageToDelete(stage);
    if (stage !== null) {
      setIsChoosedStageToDelete(true);
    } else {
      setIsChoosedStageToDelete(false);
    }
    setIsChoosedSingleItem(false);
    setchoosedSingleItem(null);
  };

  // Handle comment change
  const handleCommentChange = (docId, value) => {
    setComments((prevState) => ({
      ...prevState,
      [docId]: value,
    }));
  };

  // Toggle input field display
  const handleShowInput = (docId) => {
    setShowCommentsInputs((prevState) => ({
      ...prevState,
      [docId]: !prevState[docId],
    }));
  };

  const handleSaveSingleInput = async (doc) => {
    // console.log("for doc", doc?.id);
    // console.log("comments are", comments);
    // console.log("value is", comments[doc?.id]);

    const formData = new FormData();
    formData.append("input_data", comments[doc?.id]);
    formData.append("table_name", doc?.stage);
    formData.append("pointer_id", pointerID);

    try {
      setLoading(true);

      const response = await SaveSingleCommentNoteOfApplicationManager_API(
        formData
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        getPopUpDataFunction(pointerID);
        handleShowInput(doc?.id);

        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );

      } else {
        setLoading(false);
        console.error(
          "Error in saving",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving.");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error in saving", error);
    }
  };

  const handleAddNewDocument = (stage) => {
    const newDocId = `newDoc-${Date.now()}`;

    const newDoc = {
      id: newDocId,
      name: "",
      is_additional: true,
      documents_full_link: "",
      stage: stage,
    };

    // Update WholeApiData by adding the new document to the correct stage
    setWholeApiData((prevState) => ({
      ...prevState,
      table_data: prevState.table_data.map((item) => {
        if (item?.stage === stage) {
          return {
            ...item,
            documents: [...item?.documents, newDoc],
          };
        }
        return item;
      }),
    }));

    setShowCommentsInputs((prevState) => ({
      ...prevState,
      [newDocId]: true,
    }));
  };

  const handleConfirmDeleteSingle = async () => {

    setLoading(true);
    setIsChoosedSingleItem(false);


    const formData = new FormData();
    formData.append("id", choosedSingleItem?.id);
    formData.append("pointer_id", pointerID);

    try {
      setLoading(true);
      setIsChoosedSingleItem(false);

      const response = await deleteSingleCommentNoteOfApplicationManager_API(
        formData
      );
      // setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setShowConfirmModal(false);
        // onClose();
        getPopUpDataFunction(pointerID);
        ApplicationManagerFetch();
        setIsChoosedSingleItem(false);
        setchoosedSingleItem(null);
        setIsChoosedStageToDelete(false);
        setchoosedSingleItem(null);
        toast.success(
          response?.data?.response?.success_msg || "Deleted Successfully."
        );
      setLoading(false);

      } else {
        setLoading(false);
        console.error(
          "Error in deleting",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in deleting");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in deleting", error);
    }
  };

  const handleConfirmDeleteCompleteRequest = async () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);

    try {
      setLoading(true);
      setIsChoosedSingleItem(false);

      const response = await deleteCompleteCommentOfApplicationManager_API(
        formData
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setShowConfirmModal(false);
        onClose();
        getPopUpDataFunction(pointerID);
        ApplicationManagerFetch();
        setIsChoosedSingleItem(false);
        setchoosedSingleItem(null);
        setIsChoosedStageToDelete(false);
        setchoosedSingleItem(null);
        toast.success(
          response?.data?.response?.success_msg || "Deleted Successfully."
        );
      } else {
        setLoading(false);
        console.error(
          "Error in deleting",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in deleting");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in deleting", error);
    }
  };

  const handleConfirmDeleteStageRequest = async () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("table_name", ChoosedStageToDelete);

    try {
      setLoading(true);
      setIsChoosedStageToDelete(false);

      const response = await deleteStageCommentOfApplicationManager_API(
        formData
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setShowConfirmModal(false);
        // onClose();
        getPopUpDataFunction(pointerID);
        ApplicationManagerFetch();
        setIsChoosedSingleItem(false);
        setChoosedStageToDelete(null);
        setIsChoosedStageToDelete(false);
        setchoosedSingleItem(null);
        toast.success(
          response?.data?.response?.success_msg || "Stage Deleted Successfully."
        );
      } else {
        setLoading(false);
        console.error(
          "Error in deleting stage",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in deleting stage"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in deleting stage", error);
    }
  };

  const getPopUpDataFunction = async () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    setLoading(true);

    try {

      const response = await getNoteRecordsOfApplicationManager_API(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setWholeApiData(response?.data?.response?.data);
        setIsChoosedSingleItem(false);
        setChoosedStageToDelete(null);
        setIsChoosedStageToDelete(false);
        setchoosedSingleItem(null);
      } else {
        setLoading(false);
        console.error(
          "Error fetching records:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching records:", error);
    }
  };

  useEffect(() => {
    if (pointerID && show) {
      getPopUpDataFunction(pointerID);
    }
  }, [pointerID, show]);

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={show}
        onHide={onClose}
        animation={false}
        backdrop="static"
        centered
        size="xl"
        className={showConfirmModal ? "blur-background" : ""}
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title
            style={{ color: "#055837", cursor: "default" }}
            className="modal-title"
          >
            {WholeApiData?.heading_text}
          </Modal.Title>

          {WholeApiData?.delete_all_request_btn && (
            <button
              className="btn-danger ms-1"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
                transition: "transform 0.1s ease-in-out",
              }}
              onClick={() =>
                handleDeleteRequest(null) &&
                setIsChoosedSingleItem(false) &&
                setIsChoosedStageToDelete(false)
              }
              onMouseEnter={(e) => {
                const icon = e.currentTarget.querySelector("svg");
                if (icon) {
                  icon.style.transform = "scale(1.4)";
                  icon.style.transition = "transform 0.1s ease-in-out";
                }
              }}
              onMouseLeave={(e) => {
                const icon = e.currentTarget.querySelector("svg");
                if (icon) {
                  icon.style.transform = "scale(1)";
                }
              }}
            >
              <FaTrash style={{ marginRight: "8px" }} /> Delete All Notes
            </button>
          )}
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              maxHeight: "600px",
              overflowY: "auto",
              overflowX: "auto",
            }}
          >
            <table
              className="table table-striped table-bordered"
              style={{
                borderCollapse: "separate",
                borderSpacing: 0,
                border: "0.5px solid #fecc00",
                cursor: "default",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={6}
                    style={{
                      textAlign: "center",
                      backgroundColor: "#f8f9fa",
                      color: "#055837",
                      fontSize: "20px",
                    }}
                  >
                    {WholeApiData?.profile_full_text}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th
                    style={{
                      width: "5%",
                      padding: "8px",
                      fontWeight: "bold",
                      color: "#055837",
                    }}
                  >
                    Stage
                  </th>
                  <th style={{ width: "80%", padding: "8px" }}>Comments</th>
                  <th
                    style={{
                      width: "15%",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </th>
                </tr>

                {WholeApiData?.table_data?.length > 0
                  ? WholeApiData.table_data.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr style={{ textAlign: "left" }}>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "bold",
                              color: "#055837",
                            }}
                          >
                            {item?.stage}
                          </td>
                          <td style={{ padding: "8px" }}>
                            {item?.comments || "No comments available."}
                          </td>

                          <td
                            key={index}
                            style={{ padding: "8px", textAlign: "center" }}
                          >
                            {item?.action.map((act, actionIndex) => {
                              if (act.label === "add") {
                                return (
                                  <a
                                    key={actionIndex}
                                    className="btn btn_green_yellow"
                                    style={{
                                      marginRight: "10px",
                                      transition: "transform 0.1s ease-in-out",
                                      pointerEvents: act.disable
                                        ? "none"
                                        : "auto",
                                      opacity: act.disable ? 0.5 : 1,
                                    }}
                                    onMouseEnter={(e) =>
                                      !act.disable &&
                                      (e.currentTarget.style.transform =
                                        "scale(1.1)")
                                    }
                                    onMouseLeave={(e) =>
                                      !act.disable &&
                                      (e.currentTarget.style.transform =
                                        "scale(1)")
                                    }
                                    title="Add"
                                    disable={act?.disable}
                                    onClick={() =>
                                      handleAddNewDocument(item?.stage)
                                    }
                                  >
                                    {/* <i className="bi bi-plus"></i> */}
                                    <i className="bi bi-plus-circle"></i>
                                  </a>
                                );
                              }

                              if (act.label === "delete") {
                                return (
                                  <a
                                    key={actionIndex}
                                    className="btn btn-danger"
                                    style={{
                                      transition: "transform 0.1s ease-in-out",
                                      pointerEvents: act.disable
                                        ? "none"
                                        : "auto", // Disable logic applied
                                      opacity: act.disable ? 0.5 : 1,
                                    }}
                                    onMouseEnter={(e) =>
                                      !act.disable &&
                                      (e.currentTarget.style.transform =
                                        "scale(1.1)")
                                    }
                                    onMouseLeave={(e) =>
                                      !act.disable &&
                                      (e.currentTarget.style.transform =
                                        "scale(1)")
                                    }
                                    title="Delete"
                                    disable={act?.disable}
                                    onClick={() =>
                                      handleDeleteStageRequest(item?.stage) &&
                                      setIsChoosedStageToDelete(true) &&
                                      setIsChoosedSingleItem(false)
                                    }
                                  >
                                    <i className="bi bi-trash"></i>
                                  </a>
                                );
                              }

                              return null;
                            })}
                          </td>
                        </tr>

                        {item?.documents?.length > 0
                          ? item.documents.map((doc, docIndex) => (
                              <tr key={docIndex} style={{ textAlign: "left" }}>
                                <td style={{ padding: "8px" }}>
                                  {item?.stage}-{docIndex + 1}
                                  {"."}
                                </td>

                                <td style={{ padding: "8px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      // value={comments[doc?.id]}                                      
                                      value={comments[doc?.id] || doc?.name}

                                      onChange={(e) =>
                                        handleCommentChange(
                                          doc?.id,
                                          e.target.value
                                        )
                                      }
                                      disabled={!showCommentsInputs[doc?.id]}
                                      style={{
                                        border: "0.5px solid #fecc00",
                                        borderRadius: "5px",
                                        padding: "0.375rem 0.75rem",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {doc?.documents_full_link && (
                                      <a
                                        href={doc?.documents_full_link}
                                        download={doc?.name}
                                        className="btn btn-sm btn_yellow_green"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ flexShrink: 0 }}
                                      >
                                        <i className="bi bi-download"></i>
                                      </a>
                                    )}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  <a
                                    className="btn btn_green_yellow"
                                    style={{
                                      marginRight: "10px",
                                      transition: "transform 0.1s ease-in-out",
                                      cursor: "pointer",
                                    }}
                                    title="Edit"
                                    onMouseOver={(e) =>
                                      (e.currentTarget.style.transform =
                                        "scale(1.1)")
                                    }
                                    onMouseOut={(e) =>
                                      (e.currentTarget.style.transform =
                                        "scale(1)")
                                    }

                                    onClick={() => {

                                      // if (showCommentsInputs[doc?.id]) {
                                      //   handleSaveSingleInput(doc);
                                      // }

                                      if( showCommentsInputs[doc?.id]){
                                      handleSaveSingleInput(doc);
                                      }else{
                                      handleShowInput(doc?.id);
                                      }

                                    }}

                      
                                    
                                  >
                                    <i
                                      className={`bi ${
                                        showCommentsInputs[doc?.id]
                                          ? "bi bi-check"
                                          : "bi bi-pencil-square"
                                      }`}
                                    ></i>
                                  </a>
                                  <a
                                  
                                    className="btn btn-danger"
                                    style={{
                                      transition: "transform 0.1s ease-in-out",
                                      cursor: "pointer",
                                    }}
                                    title="Delete"
                                    onMouseOver={(e) =>
                                      (e.currentTarget.style.transform =
                                        "scale(1.1)")
                                    }
                                    onMouseOut={(e) =>
                                      (e.currentTarget.style.transform =
                                        "scale(1)")
                                    }
                                    onClick={() =>
                                      handleDeleteRequest(doc) &&
                                      setIsChoosedSingleItem(true) &&
                                      setIsChoosedStageToDelete(false)
                                    }
                                  >
                                    {/* <i className="bi bi-trash"></i> */}
                                    {/* <i className="fas fa-trash-alt"></i> */}
                                    {/* <i className="fas fa-trash"></i> */}
                                    <i className="fas fa-trash-restore"></i>
                                  </a>
                                </td>
                              </tr>
                            ))
                          : // <tr>
                            //   <td
                            //     colSpan={3}
                            //     style={{ textAlign: "center", padding: "8px" }}
                            //   >
                            //     No documents available for this stage.
                            //   </td>
                            // </tr>
                            ""}
                      </React.Fragment>
                    ))
                  : // <tr>
                    //   <td
                    //     colSpan={3}
                    //     style={{ textAlign: "center", padding: "8px" }}
                    //   >
                    //     No data available
                    //   </td>
                    // </tr>
                    ""}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn_modal"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
        backdrop="static"
        dialogClassName="custom-modal-width"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.6)",
          zIndex: 99999999,
          display: showConfirmModal ? "block" : "none",
          cursor: "default",
        }}
      >
        {loading && <Loader />}

        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{" "}
          {(() => {
            if (
              choosedSingleItem &&
              IsChoosedSingleItem &&
              !IsChoosedStageToDelete
            ) {
              return (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    cursor: "default",
                  }}
                >
                  {choosedSingleItem?.name}
                </span>
              );
            } else if (IsChoosedStageToDelete && ChoosedStageToDelete) {
              return (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    cursor: "default",
                  }}
                >
                  {ChoosedStageToDelete}
                </span>
              );
            } else {
              return (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    cursor: "default",
                  }}
                >
                  all comments
                </span>
              );
            }
          })()}
          {"?"}
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() =>
              setShowConfirmModal(false) &&
              setIsChoosedSingleItem(false) &&
              setIsChoosedStageToDelete(false)
            }
            style={{
              padding: "7px 20px",
              borderRadius: "6px",
              border: "none",
            }}
          >
            No
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              if (IsChoosedSingleItem) {
                handleConfirmDeleteSingle();
              } else if (IsChoosedStageToDelete) {
                handleConfirmDeleteStageRequest();
              } else {
                handleConfirmDeleteCompleteRequest();
              }
            }}
            style={{
              padding: "7px 20px",
              borderRadius: "6px",
              border: "none",
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AdditionalImportNotePopup;
