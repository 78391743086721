import React, { useState } from "react";
import { postSaveReminderDateApplicationManager } from "../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Widgets/Loader/Loader";

// Helper function to convert Date object to "DD/MM/YYYY"
const toDateFormat = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-").map(Number);
  return `${String(day).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`;
};

// Helper function to convert "DD/MM/YYYY" to "YYYY-MM-DD"
const toDateInputFormat = (dateString) => {
  if (!dateString || dateString === "dd/mm/yyyy") return ""; // Handle empty case for placeholder
  const [day, month, year] = dateString.split("/").map(Number);
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
};

const Reminder_Date_Chooser = ({
  pointerID,
  reminderDate,
  setReminderDate,
  setHideShowReminder,
  getAllDataOfViewUpdateFunction
}) => {
  const [loading, setLoading] = useState(false);

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    if (dateValue === "") {
      setReminderDate(""); // Set empty string when the date is cleared
    } else {
      setReminderDate(toDateFormat(dateValue));
    }
  };

  const saveNewReminderDate = async () => {
    try {
      setLoading(true);

      // Handle invalid date like "-1-11-30"
      const formattedDate = reminderDate === "" ? "" : toDateInputFormat(reminderDate);

      const formData = new FormData();
      formData.append("pointer_id", pointerID);
      formData.append("date_lodged_info", formattedDate);

      const response = await postSaveReminderDateApplicationManager(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        console.log(response?.data?.response?.data);
        setHideShowReminder();
        getAllDataOfViewUpdateFunction(pointerID);
      } else {
        setLoading(false);
        console.error("Error saving reminder date:", response?.data?.response?.error_msg || "Unknown error");
        toast.error(response?.data?.response?.error_msg || "Error saving reminder date:");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving reminder date:", error);
      toast.error("Error saving reminder date. Please try again.");
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="input_output_box">
        <input
          type="date"
          className="form-control"
          value={toDateInputFormat(reminderDate)}
          onChange={handleDateChange}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="actions_btns">
        <a
          onClick={saveNewReminderDate}
          href="javascript:void(0)"
          className="btn btn-sm btn_green_yellow"
          id="followup_action_btn"
          style={{ marginTop: 1, marginLeft: 1, padding: 6, cursor: "pointer" }}
        >
          <i className="bi bi-check-circle" title="Save" />
        </a>
      </div>
    </>
  );
};

export default Reminder_Date_Chooser;
