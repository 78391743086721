import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  getShowContractDetailsAPI,
  UpdateShowContractDetailsAPI,
} from "../../../api";
import Loader from "../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdditionalDetailModal = ({ showModal, handleClose, SelectedItem }) => {
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [allContactData, setAllContactData] = useState({});

  const [loading, setLoading] = useState(false);

  const handleTextareaChange = (event) => {
    setAdditionalDetails(event.target.value);
  };

  const getDataEmploymentFunction = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", SelectedItem.id);

      const response = await getShowContractDetailsAPI(formData);
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const incompleteApplicationsData = response?.data?.response?.data;
        setAdditionalDetails(incompleteApplicationsData?.contract_details);
        setAllContactData(incompleteApplicationsData);
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (showModal && SelectedItem) {
      getDataEmploymentFunction();
    }
  }, [SelectedItem, showModal]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", SelectedItem.id);
      formData.append("contract_details", additionalDetails);

      const response = await UpdateShowContractDetailsAPI(formData);
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        handleClose();
        toast.success(
          response?.data?.response?.success_msg || "Updated Successfully"
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal show={showModal} onHide={handleClose} animation={false} centered>
        <form onSubmit={handleSubmit}>
          <Modal.Body style={{ cursor: "default" }}>
            <h6 className="modal-title text-green">
              {allContactData?.name} {allContactData?.middle_name}{" "}
              {allContactData?.last_name} - {allContactData?.business_name}{" "}
              <br />
              {allContactData?.email}
            </h6>

            <div className="form-group">
              <label htmlFor="otherReasonTextarea" className="mb-0">
                <p className="mb-0 label_font_size">
                  Additional Contact Details
                </p>
              </label>
              <textarea
                id="otherReasonTextarea"
                className="form-control"
                placeholder="Please specify"
                value={additionalDetails}
                onChange={handleTextareaChange}
              ></textarea>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="btn-danger ms-1"
              variant="primary"
              onClick={handleClose}
            >
              Close
            </Button>

            <button
              className="btn_modal"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              type="submit"
            >
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AdditionalDetailModal;
