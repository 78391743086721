import React, { useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Loader from "../../../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { postEditFileOfSubmittedApplicationsOfViewUpdateApplicationForm } from "../../../../../api";

const EditImageModal = ({
  show,
  onHide,
  selectedDoc,
  pointerID,
  getAllDataOfSubmittedApplicationsFunction,
  stage,
}) => {
  const [formValues, setFormValues] = useState({
    file: null,
    error: "",
    fileError: false,
    fileName: "", // Stores the file name
  });

  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  // Convert document required types to valid MIME types
  const getValidFileTypes = () => {
    const fileTypeMap = {
      pdf: "application/pdf",
      png: "image/png",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      heic: "image/heic",
      mp3: "audio/mpeg",
      mp4: "video/mp4",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };

    // Ensure selectedDoc?.document_required is an array before mapping
    return (selectedDoc?.document_required || []).map(type => fileTypeMap[type]).filter(Boolean);
  };

  const validFileTypes = getValidFileTypes();

  // Create dynamic list of supported file types for display
  const getSupportedFileTypesText = () => {
    const extensionMap = {
      "application/pdf": ".pdf",
      "image/png": ".png",
      "image/jpeg": ".jpg / .jpeg",
      "image/heic": ".heic",
      "audio/mpeg": ".mp3",
      "video/mp4": ".mp4",
      "application/vnd.ms-excel": ".xls",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
    };

    return validFileTypes.length > 0
      ? validFileTypes.map(type => extensionMap[type]).join(" / ")
      : "No supported file types available";
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (validFileTypes.includes(selectedFile.type)) {
        const filePreview = {
          file: selectedFile,
          preview: URL.createObjectURL(selectedFile), // Generate URL for both image and PDF files
        };

        setFormValues({
          file: filePreview,
          error: "",
          fileError: false,
          fileName: selectedFile.name, // Update the fileName with selected file name
        });
      } else {
        setFormValues({
          file: null,
          error: "Unsupported file type. Please select a valid file type.",
          fileError: true,
          fileName: "",
        });

        // Show toast notification for invalid file type
        toast.error("Unsupported file type. Please select a valid file type.");
      }
    }
  };

  const handleSubmit = async () => {
    if (!formValues.file) {
      setFormValues({
        ...formValues,
        error: "Please select a file to upload.",
        fileError: true,
      });
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", formValues.file.file);
      formData.append("stage", stage);
      formData.append("pointer_id", pointerID);
      formData.append("document_id", selectedDoc?.document_id);

      const response =
        await postEditFileOfSubmittedApplicationsOfViewUpdateApplicationForm(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Updated Successfully."
        );

        getAllDataOfSubmittedApplicationsFunction();
        onHide();
      } else {
        setLoading(false);
        console.error(
          "Error in Saving",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.error_msg || "Error in Deleting");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Updated", error);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            Edit {selectedDoc?.document_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFile">
              <Form.Label>Upload a file</Form.Label>
              <div className="d-flex w-100">
  {/* Hidden file input */}
  <input
    type="file"
    className={`form-control ${formValues.fileError ? "is-invalid" : ""}`}
    onChange={handleFileChange}
    ref={fileInputRef}
    style={{ display: "none" }} // Hides the default input
    id="hiddenFileInput"
    accept={getSupportedFileTypesText()}
  />

  {/* Custom input field to show file name */}
  <input
    type="text"
    className="form-control"
    placeholder="Choose file..."
    value={formValues.fileName || ""}
    readOnly
    onClick={() => fileInputRef.current.click()} // Triggers file input on click
    style={{
      cursor: "pointer", // Adds a pointer cursor
      backgroundColor: "white", // Sets background to white
      flexGrow: 1, // Makes input take up remaining space
    }}
  />

  <Button
    variant="outline-secondary"
    onClick={() => fileInputRef.current.click()}
    className="ms-2"
  >
    Browse
  </Button>
</div>

              <Form.Text className="text-danger">
                Only: {getSupportedFileTypesText()}
              </Form.Text>
              {formValues.fileError && (
                <div className="invalid-feedback">{formValues.error}</div>
              )}
            </Form.Group>

            {/* Preview Section */}
            {formValues.file && (
              <div className="mt-3">
                <strong>Preview: </strong>
                {formValues.file.file.type === "application/pdf" ? (
                  <iframe
                    src={formValues.file.preview}
                    title="PDF Preview"
                    style={{
                      width: "100%",
                      height: "300px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  />
                ) : formValues.file.file.type.startsWith("image/") ? (
                  <img
                    src={formValues.file.preview}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      height: "300px",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                ) : formValues.file.file.type.startsWith("video/") ? (
                  <video
                    src={formValues.file.preview}
                    controls
                    style={{
                      width: "100%",
                      height: "300px",
                      borderRadius: "8px",
                    }}
                  />
                ) : formValues.file.file.type.startsWith("audio/") ? (
                  <audio
                    src={formValues.file.preview}
                    controls
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  <p>Unsupported file type for preview</p>
                )}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-danger ms-1"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onHide}
          >
            Close
          </Button>
          <Button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditImageModal;
