import { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddOccupationAPI } from "../../../api";
import Loader from "../../Widgets/Loader/Loader";

const AddOccupationModal = ({ showModal, handleClose ,getOccupationsFunction}) => {
  const [loading, setLoading] = useState(false);
  const [applicantOccupation, setApplicantOccupation] = useState("");
  const [applicantOccupationCode, setApplicantOccupationCode] = useState("");
  const [status, setStatus] = useState("active");

  const handleOccupationChange = (event) => {
    setApplicantOccupation(event.target.value);
  };

  const handleOccupationCodeChange = (event) => {
    setApplicantOccupationCode(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleAdd = async (event) => {
    event.preventDefault();

    if (!applicantOccupation) {
      toast.error("Occupation is required.");
      return;
    }

    if (!applicantOccupationCode) {
      toast.error("Occupation Code is required.");
      return;
    }

    try {
      setLoading(true);

      // Create a new FormData object
      const formData = new FormData();
      formData.append("name", applicantOccupation);
      formData.append("number", applicantOccupationCode);
      formData.append("status", status);

      const response = await AddOccupationAPI(formData);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true &&
        response.data.response.data
      ) {
        handleClose();
        getOccupationsFunction();
        toast.success(response.data.response.success_msg || "Occupation Added Successfully.");
      } else {
        toast.error(response.data.error_msg || "Error adding occupation.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred.");
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal show={showModal} onHide={handleClose} animation={false} centered>
        <form onSubmit={handleAdd}>
          <Modal.Header>
            <Modal.Title className="modal-title text-center text-green">
              <h5>Add Occupation</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="6">
                <div className="zoom_details">
                  <label htmlFor="applicantOccupation" className="form-label label-font-size">
                    <p className="para">
                      Occupation <span className="text-danger">*</span>
                    </p>
                  </label>
                  <div className="zoom_details">
                    <input
                      name="applicantOccupation"
                      type="text"
                      className="form-control"
                      id="applicantOccupation"
                      value={applicantOccupation}
                      onChange={handleOccupationChange}
                      required
                    />
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="zoom_details">
                  <label htmlFor="applicantOccupationCode" className="form-label label-font-size">
                    <p className="para">
                      Occupation Code <span className="text-danger">*</span>
                    </p>
                  </label>
                  <div className="zoom_details">
                    <input
                      name="applicantOccupationCode"
                      type="text"
                      className="form-control"
                      id="applicantOccupationCode"
                      value={applicantOccupationCode}
                      onChange={handleOccupationCodeChange}
                      required
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <label>
                  Status <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  name="status"
                  onChange={handleStatusChange}
                  value={status}
                  style={{cursor:"pointer"}}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="btn_yellow_green"
              style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="btn_modal"
              type="submit"
              style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
              onSubmit={handleAdd}
            >
              Add
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddOccupationModal;
