import { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import {
  getDropdownOptionsDetailNonAQATOAPI,
  postNonAquatoInterviewBooking,
} from "../../../../api";
import Loader from "../../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import VerificationModal from "../VerificationModal/VerificationModal";

const NewBookingModal = ({
  showModal,
  handleClose,
  getNonAquatoDataFunction,
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    interview_venue: "",
    date: "",
    dob: "",
    time: "",
    full_name: "",
    unique_number: "",
    occupation_name: "",
    pathway: "",
  });

  const [showDeleteAccountModal, setDeleteAccountShow] = useState(false);
  const handleDeleteAccountClose = () => setDeleteAccountShow(false);
  const handleDeleteAccountShow = () => {
    setDeleteAccountShow(true);
  };

const today = new Date().toLocaleDateString('en-CA', { 
  timeZone: 'Australia/Brisbane'
});

  const [occupations, setOccupations] = useState([]);
  const [pathways, setPathways] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [venues, setVenues] = useState([]);

  const getSingleZoomDetailFunction = async () => {
    try {
      setLoading(true);
      const response = await getDropdownOptionsDetailNonAQATOAPI();
      setLoading(false);
      if (
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setOccupations(response?.data?.response?.data?.occupations);
        setPathways(response?.data?.response?.data?.pathways);
        setTimeSlots(response?.data?.response?.data?.time_options);
        setVenues(response?.data?.response?.data?.venues);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching zoom details:", error);
    }
  };
  useEffect(() => {
    if (showModal) {
      getSingleZoomDetailFunction();
    }
  }, [showModal]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const isAnyFieldEmpty = Object.values(formData).some(
      (field) => !field.trim()
    );

    if (isAnyFieldEmpty) {
      toast.error("Please fill in all fields.");
      return false;
    }

    return true;
  };

  const handleSubmitCheck = () => {
    if (validateForm()) {
      handleDeleteAccountShow();
    }
  };


  const handleClear = () => {
    setFormData({
      interview_venue: "",
      date: "",
      dob: "",
      time: "",
      full_name: "",
      unique_number: "",
      occupation_name: "",
      pathway: "",
    });
  };




  const handleSubmit = async () => {
    // Create FormData instance
    const formDataToSend = new FormData();

    formDataToSend.append("interview_location", formData.interview_venue);
    formDataToSend.append("date", formData.date);
    formDataToSend.append("dob", formData.dob);
    formDataToSend.append("time", formData.time);
    formDataToSend.append("full_name", formData.full_name);
    formDataToSend.append("unique_number", formData.unique_number);
    formDataToSend.append("occupation_name", formData.occupation_name);
    formDataToSend.append("pathway", formData.pathway);

    try {
      setLoading(true);
      const response = await postNonAquatoInterviewBooking(formDataToSend);
      setLoading(false);

      if (
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        getNonAquatoDataFunction();
        toast.success(
          response?.data?.response?.success_msg ||
            "Interview Booking done successfully."
        );

        setFormData({
          interview_venue: "",
          date: "",
          dob: "",
          time: "",
          full_name: "",
          unique_number: "",
          occupation_name: "",
          pathway: "",
        });

        handleClear();

        handleClose();
        handleDeleteAccountClose();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to schedule interview.");
      console.error("Error while submitting new details:", error);
      toast.error("Unknown Error.");
    }
  };

  const handleFakeSubmit = async (event) => {
    event.preventDefault();
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        show={showModal}
        // onHide={handleClose}
        onHide={() => {
          handleClose();
          handleClear();
        }}
        backdrop="static"
        centered
        size="xl"
      >
        <div className="">
          <form onSubmit={handleFakeSubmit}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center text-green">
                <h5>Add Interview Booking ( Non - AQATO )</h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row className="mb-2">
                <Col md="6">
                  <label htmlFor="full_name" className="form-label">
                    <b>
                      Applicant Full Name <span className="text-danger">*</span>
                    </b>
                  </label>
                  <input
                    name="full_name"
                    type="text"
                    className="form-control"
                    id="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    required
                  />
                </Col>

                <Col md="6">
                  <label htmlFor="unique_number" className="form-label">
                    <b>
                      Application No. <span className="text-danger">*</span>
                    </b>
                  </label>
                  <input
                    name="unique_number"
                    type="text"
                    className="form-control"
                    id="unique_number"
                    value={formData.unique_number}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md="6">
                  <label htmlFor="occupation_name" className="form-label">
                    <b>
                      Occupation <span className="text-danger">*</span>
                    </b>
                  </label>
                  <select
                    name="occupation_name"
                    id="occupation_name"
                    className="form-select"
                    value={formData.occupation_name}
                    onChange={handleChange}
                    required
                    style={{ cursor: "pointer" }}
                  >
                    <option disabled value="">
                      Select Occupation
                    </option>
                    {occupations.map((occupation) => (
                      <option key={occupation?.id} value={occupation?.name}>
                        {occupation?.name}
                      </option>
                    ))}
                  </select>
                </Col>

                <Col md="6">
                  <label htmlFor="interview_venue" className="form-label">
                    <b>
                      Venue <span className="text-danger">*</span>
                    </b>
                  </label>
                  <select
                    name="interview_venue"
                    id="interview_venue"
                    className="form-select"
                    value={formData.interview_venue}
                    onChange={handleChange}
                    required
                    style={{ cursor: "pointer" }}
                  >
                    <option disabled value="">
                      Select Venue
                    </option>

                    {venues.map((countryObj) =>
                      Object.entries(countryObj).map(([country, locations]) => (
                        <optgroup
                          key={country}
                          label={country}
                          style={{ fontWeight: "bold" }}
                        >
                          {locations.map((location) => (
                            <option
                              key={location.venue_id}
                              value={location.venue_id}
                              style={{ cursor: "pointer" }}
                            >
                              {location.venue_name}
                            </option>
                          ))}
                        </optgroup>
                      ))
                    )}
                  </select>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md="6">
                  <label htmlFor="date" className="form-label">
                    <b>
                      Interview Date <span className="text-danger">*</span>
                    </b>
                  </label>
                  <input
                    name="date"
                    type="date"
                    className="form-control"
                    id="date"
                    value={formData.date}
                    min={today}
                    onChange={handleChange}
                    required
                    style={{ cursor: "pointer" }}
                  />
                </Col>

                <Col md="6">
                  <label htmlFor="time" className="form-label">
                    <b>
                      Interview Time <span className="text-danger">*</span>
                    </b>
                  </label>
                  <select
                    name="time"
                    id="time"
                    className="form-select"
                    value={formData.time}
                    onChange={handleChange}
                    required
                    style={{ cursor: "pointer" }}
                  >
                    <option disabled value="">
                      Select Time
                    </option>
                    {timeSlots.map((timeSlot, index) => (
                      <option key={index} value={timeSlot?.time_start}>
                        {timeSlot?.time_start}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md="6">
                  <label htmlFor="dob" className="form-label">
                    <b>Date of Birth</b>
                  </label>
                  <input
                    name="dob"
                    type="date"
                    className="form-control"
                    id="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    max={today}
                    style={{ cursor: "pointer" }}
                  />
                </Col>

                <Col md="6">
                  <label htmlFor="pathway" className="form-label">
                    <b>
                      Pathway <span className="text-danger">*</span>
                    </b>
                  </label>
                  <select
                    name="pathway"
                    id="pathway"
                    className="form-select"
                    value={formData.pathway}
                    onChange={handleChange}
                    required
                    style={{ cursor: "pointer" }}
                  >
                    <option disabled value="">
                      Select Pathway
                    </option>
                    {pathways.map((pathway) => (
                      <option key={pathway} value={pathway}>
                        {pathway}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <button
                className="btn_yellow_green"
                style={{
                  padding: "7px 20px",
                  borderRadius: "6px",
                  border: "none",
                }}
                // onClick={handleClose}
                onClick={() => {
                  handleClose();
                  handleClear();
                }}
              >
                Close
              </button>
              <button
                className="btn_modal"
                variant="primary"
                // onClick={handleSubmit}
                onClick={() => handleSubmitCheck()}
                style={{
                  padding: "7px 20px",
                  borderRadius: "6px",
                  border: "none",
                }}
                type="submit"
              >
                Book Interview
              </button>
            </Modal.Footer>
          </form>
        </div>
      </Modal>

      {showDeleteAccountModal && showModal && (
        <VerificationModal
          show={showDeleteAccountModal}
          // onClose={handleDeleteAccountClose}
          onClose={() => {
            handleDeleteAccountClose();
            handleClear();
          }}
          onYes={handleSubmit}
          Title="Confirm Booking!"
          Message={
            <>
              Are you sure you want to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                &nbsp; Add New Interview Booking
              </span>
              &nbsp; ?
            </>
          }
        />
      )}
    </>
  );
};

export default NewBookingModal;
