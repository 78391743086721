import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Widgets/Loader/Loader";
import { postDownoloadZipFileForSubmittedApplicationsOfApplicationManager } from "../../../../../api";

const EnsureFileDownloadModal = ({
  show,
  onHide,
  stage,
  pointerID,
  getAllDataOfSubmittedApplicationsFunction,
}) => {
  const [loading, setLoading] = useState(false);

  const downloadZip = async () => {
    // onHide(); // Close the modal after success
    // const fileLink = process.env.REACT_APP_AQATO_ADMIN_PORTAL_BASE_API_URL+"/download_zip_get_api/"+pointerID+"/"+stage;
    // // Use window.open to open the link in a small window
    // window.open(fileLink, "_blank", "width=500,height=400");
    // return;

    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);

    try {
      setLoading(true);

      const response =
        await postDownoloadZipFileForSubmittedApplicationsOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true &&
        response.data.response.data
      ) {
        // toast.success(
        //   response.data.response.success_msg ||
        //     "Download initiated successfully."
        // );
        const fileLink = response?.data?.response?.data?.link;
        window.open(fileLink, "_blank");
        // Call the function to refresh data
        getAllDataOfSubmittedApplicationsFunction(pointerID);
        onHide(); // Close the modal after success
      } else {
        toast.error(
          response.data.response.error_msg || "Error during download."
        );
        onHide();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during the download process:", error);
      toast.error("An error occurred while downloading the file.");
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={show}
        onHide={onHide}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            Confirm!
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Are you sure you want to{" "}
            <span style={{ fontWeight: "bold", color: "#055837" }}>
              Download
            </span>{" "}
            all files?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-danger ms-1"
            variant="primary"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onHide}
          >
            No
          </Button>
          <Button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={downloadZip}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnsureFileDownloadModal;
