import React from "react";
import { Modal } from "react-bootstrap";

const VerificationModal = ({ show, onClose, Title, Message, onYes }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        animation={false}
        backdrop="static"
        centered
        style={{    background: "rgba(0, 0, 0, 0.6)"}}
      >
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            {Title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label className="mb-0">
              <p className="mb-0" style={{color:"black"}}>{Message}</p>
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-danger ms-1"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onClose}
          >
            No
          </button>
          <button
              className="btn btn_yellow_green"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onYes}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VerificationModal;
