import React, { useState, useEffect } from "react";
import EditRTODetailsModal from "./EditRTODetailsModal/EditRTODetailsModal";
// import "./RefereeDetails.css";
import SidebarMenu from "../../../Widgets/SideBar Menu/SidebarMenu.js";
import {
  getIndividualVerificationAPI,
  changeStatusOfRTO,
} from "../../../../api.js";
import Loader from "../../../Widgets/Loader/Loader.js";
import VerificationModal from "./VerificationModal/VerificationModal.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const RTODetails = () => {
  const location = useLocation();
  const { id } = location.state || {};

  const navigate = useNavigate();
  const isQualification = true;

  const [loading, setLoading] = useState(false);
  const [showChangeStatusModal, setChangeStatusModalShow] = useState(false);

  const [SelectedItem, setSelectedItem] = useState(null);
  const [applicantData, setApplicantData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  const getIndividualEmploymentFunction = async () => {
    try {
      setLoading(true);
      const response = await getIndividualVerificationAPI(id);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response.data
      ) {
        const individualData = response.data.response.data;
        const employeeDataset = response.data.response.data.employs;

        setApplicantData(individualData.applicant_data);
        setEmployeeData(employeeDataset);
      } else {
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching occupations data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getIndividualEmploymentFunction();
    }
  }, [id]);

  const handleChangeStatusModalShow = (item) => {
    setSelectedItem(item);
    setChangeStatusModalShow(true);
    setEditRefereeDetailsModalShow(false);
  };

  const handleChangeStatusModalClose = () => setChangeStatusModalShow(false);

  const [showEditRefereeDetailsModal, setEditRefereeDetailsModalShow] =
    useState(false);
  const handleEditRefereeDetailsModalClose = () =>
    setEditRefereeDetailsModalShow(false);

  const handleEditRefereeDetailsModalShow = (item) => {
    setSelectedItem(item);
    setEditRefereeDetailsModalShow(true);
    setChangeStatusModalShow(false);
  };

  const handleChangeStatus = async (SelectedItem) => {
    try {
      setLoading(true);

      // const status = SelectedItem?.status === "Verified" ? "Pending" : "Verified";
      const status = SelectedItem?.status === "Verified" ? "Verified" : "Pending";

      const formData = new FormData();
      // formData.append("pointer_id", id);
      formData.append("id", SelectedItem?.id);
      formData.append("status", status);


      const response = await changeStatusOfRTO(formData);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        toast.success(
          response.data.response.success_msg || "Status Changed successfully."
        );
        getIndividualEmploymentFunction();
        handleChangeStatusModalShow(false);
        setSelectedItem(null);
      } else {
        toast.error(response.data.error_msg || "Error changing status ");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred.");
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleNavigateQualification = (e, isQualification) => {
    e.preventDefault();
    navigate("/admin/verification", { state: { isQualification } });
  };

  return (
    <>
      {loading && <Loader />}

      <div>
        <div className="main_desktop ">
          <hr className="mb-2 thick-hr" />
          <div className="main-wrapper ">
            <div className="sub-wrapper-sidebar sidebar-margin">
              <SidebarMenu />
            </div>

            <div className="sub-wrapper-main mb-5">
              <div className="main-heading-title d-flex">
                <div className="col-6">
                  <h3 className="heading-admin-title text-color-green">
                    RTO Details
                  </h3>
                </div>
                <div className="col-6 namekey_btnback">
                  <button
                    onClick={(e) =>
                      handleNavigateQualification(e, isQualification)
                    }
                    className="btn btn_yellow_green float-end"
                  >
                    <i className="bi bi-arrow-left"></i>
                    Back
                  </button>
                </div>
              </div>

              <section className="section dashboard mt-0 shadow">
                <div className="row">
                  <div className="card shadow card_maual">
                    <div className="card-body">
                      <div className="table table-responsive">
                        <table className="table ">
                          <tbody style={{ cursor: "default" }}>
                            <tr>
                              <th width="25%">
                                <h6>PRN </h6>
                              </th>
                              <td>:</td>
                              <td>
                                <b>{applicantData?.prn}</b>
                              </td>
                            </tr>
                            <tr>
                              <th width="25%">
                                <h6>Application No.</h6>
                              </th>
                              <td>:</td>
                              <td>
                                <b>{applicantData?.application_no}</b>
                              </td>
                            </tr>
                            <tr>
                              <th width="25%">
                                <h6>Applicant Name</h6>
                              </th>
                              <td>:</td>
                              <td>{applicantData?.applicant_name}</td>
                            </tr>
                            <tr>
                              <th width="25%">
                                <h6>Agent Name</h6>
                              </th>
                              <td>:</td>
                              <td>{applicantData?.user_info?.user_fullname}</td>
                            </tr>
                            <tr>
                              <th width="25%">
                                <h6>Agency</h6>
                              </th>
                              <td>:</td>
                              <td>{applicantData?.user_info?.business_name}</td>
                            </tr>
                            <tr>
                              <th width="25%">
                                <h6>Agent Contact No.</h6>
                              </th>
                              <td>:</td>
                              <td>{applicantData?.user_info?.mobile_no}</td>
                            </tr>
                          </tbody>
                        </table>

                        <hr className="thick-hr" />

                        <table
                          id
                          className="table"
                          style={{ backgroundColor: "#f6f6f6" }}
                        >
                          <thead>
                            <tr style={{ cursor: "default" }}>
                              <th style={{ width: "10%" }}>Sr. No.</th>
                              <th style={{ width: "30%" }}>Email ID</th>
                              <th style={{ width: "35%" }}>CC Email ID</th>
                              <th style={{ width: "15%" }}>Status</th>
                              <th style={{ width: "10%" }}>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {employeeData.map((item, index) => {
                              return (
                                <tr key={index} style={{ cursor: "default" }}>
                                  <th>{index + 1}.</th>
                                  <td>{item?.verification_email_id}</td>
                                  <td>{item?.email_cc_id}</td>

                                  <td className="td_font_size align-left">
                                    <button
                                      className={`btn btn-sm ms-1 ${
                                        item.status === "Pending"
                                          ? "btn-danger"
                                          : item.status === "[#T.B.A]"
                                          ? ""
                                          : "btn_green_yellow"
                                      }`}
                                      style={{
                                        width: "85px",
                                        // height: "35px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor:
                                          item.status !== "[#T.B.A]"
                                            ? "pointer"
                                            : "default",
                                      }}
                                      onClick={
                                        item.status !== "[#T.B.A]"
                                          ? () =>
                                              handleChangeStatusModalShow(item)
                                          : null
                                      }
                                    >
                                      <strong>
                                        {item?.status?.toUpperCase()}
                                      </strong>
                                    </button>
                                  </td>

                                  <td>
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#edit_form1"
                                      className="btn btn-sm btn_green_yellow ms-1"
                                      onClick={() =>
                                        handleEditRefereeDetailsModalShow(item)
                                      }
                                    >
                                      <i className="bi bi-pencil-square"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        {showEditRefereeDetailsModal && SelectedItem && (
          <EditRTODetailsModal
            show={showEditRefereeDetailsModal}
            handleClose={() =>
              handleEditRefereeDetailsModalClose && setSelectedItem(null)
            }
            getIndividualEmploymentFunction={getIndividualEmploymentFunction}
            pointerID={id}
            call={true}
            SelectedItem={SelectedItem}
          />
        )}

        {showChangeStatusModal && SelectedItem && (
          <VerificationModal
            show={showChangeStatusModal}
            onClose={() =>
              handleChangeStatusModalClose && setSelectedItem(null)
            }
            onYes={() => handleChangeStatus(SelectedItem)}
            Title="Confirm Change Status !"
            Message={
              <>
                Are you sure you want to change status of{" "}
                <strong>{SelectedItem?.verification_email_id} </strong> from{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color:
                      SelectedItem?.status === "Verified" ? " #055837" : "red",
                  }}
                >
                  &nbsp; {capitalizeFirstLetter(SelectedItem?.status)}
                </span>
                &nbsp; to{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color:
                      SelectedItem?.status === "Verified" ? "red" : " #055837",
                  }}
                >
                  &nbsp;{" "}
                  {capitalizeFirstLetter(
                    SelectedItem?.status === "Verified" ? "Pending" : "Verified"
                  )}
                </span>
                ?
              </>
            }
          />
        )}
      </div>
    </>
  );
};

export default RTODetails;
