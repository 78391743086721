import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Dropdown,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./NotesChat.css";
import {
  deletedMassagesOfNotes,
  getAllMassagesOfNotes,
  sendMassagesOfNotes,
} from "../../../api";

const NotesChat = ({ pointerID, NotesTabActive, NotesTabCount }) => {
  const admin_ID_FromStorage = localStorage.getItem("AQATO_SuperAdminID") || "";
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  const [loading, setLoading] = useState(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [RecordFetchNote, setRecordFetchNote] = useState(100000000000);
  const [OffsetRecordNote, setOffsetRecordNote] = useState(0);
  const [AllData, setAllData] = useState([]);

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedMassageWithFile, setSelectedMassageWithFile] = useState("");

  const [expandedMessages, setExpandedMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [replyingTo, setReplyingTo] = useState(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (NotesTabActive) {
      scrollToBottom();
    }
  }, [messages, NotesTabActive]);

  const getAllMassagesOfNotesFun = async () => {
    const formData = new FormData();
    formData.append("userTimezone", userTimezone);
    formData.append("pointer_id_global", pointerID);
    formData.append("record_fetch_note", RecordFetchNote);
    formData.append("offset_record_note", OffsetRecordNote);

    try {
      // setLoading(true);

      const response = await getAllMassagesOfNotes(formData);

      // setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setAllData(response?.data?.response?.data);
        setMessages(response?.data?.response?.data);

        // toast.success(
        //   response?.data?.response?.success_msg || "Fetched Successfully."
        // );
      } else {
        // setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in fetching");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (NotesTabActive) {
      getAllMassagesOfNotesFun();
    }
  }, [NotesTabActive]);

  useEffect(() => {
    getAllMassagesOfNotesFun();
  }, [NotesTabCount]);

  const SendMassageFunction = async () => {
    const formData = new FormData();
    formData.append("userTimezone", userTimezone);
    formData.append("pointer_id", pointerID);
    formData.append("admin_id", admin_ID_FromStorage);

    // formData.append("reply_id", "");

    formData.append("lastinsertedids[]", "");

    if (input.trim() !== "") {
      formData.append("message", input);
      formData.append("note_file[]", "");
    } else if (selectedFile) {
      formData.append("message", selectedMassageWithFile);
      formData.append("note_file[]", selectedFile);
    } else {
      showToast("Please enter a message or choose a file before sending.");
      return;
    }

    if (replyingTo) {


      // formData.append("reply_msg_note", replyingTo?.message);




      if (replyingTo?.type === "text") {
        formData.append("reply_msg_note", replyingTo?.message);
      } else {
        formData.append("reply_msg_note", replyingTo?.documents);
      }
      



    } else {
      formData.append("reply_msg_note", "");
    }

    if (replyingTo) {
      formData.append("reply_id_note", replyingTo?.id);
    } else {
      formData.append("reply_id_note", "");
    }

    try {
      // setLoading(true);

      const response = await sendMassagesOfNotes(formData);

      // setLoading(false);

      if (
        response &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        getAllMassagesOfNotesFun();
        // toast.success(response?.data?.response?.success_msg || "Sent successfully.");
      } else {
        console.error(
          "Error fetching data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in sending");
        getAllMassagesOfNotesFun();
      }
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("Error sending the message or file.");
      getAllMassagesOfNotesFun();
    }
  };

  const handleSend = () => {
    if (input.trim() !== "") {
      setMessages([
        ...messages,
        {
          type: "text",
          message: input,
          id: Date.now().toString(),
          replyTo: replyingTo,
        },
      ]);

      // Call the API to send the message
      SendMassageFunction();

      // Clear input and replying state
      setInput("");
      setReplyingTo(null);
    } else {
      showToast("Please enter a message or choose a file before sending.");
    }
  };

  const handleSendFile = () => {
    if (selectedFile) {
      setMessages([
        ...messages,
        {
          type: "file",
          message: selectedFile,
          id: Date.now().toString(),
          replyTo: replyingTo,
        },
      ]);

      SendMassageFunction();

      setSelectedFile(null);
      setReplyingTo(null);
      setSelectedMassageWithFile("")
      handleCancelFile(selectedFile);
    } else {
      showToast("Please select a file before sending.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        showToast(
          "Unsupported file type. Please select an image, video, PDF, document, ZIP, or MP3 file."
        );
      }
    }
  };

  const handleCancelFile = (selectedFile) => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile); // Create the object URL
      URL.revokeObjectURL(objectUrl); 
    }

    setSelectedFile(null); // Clear the selected file
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    setSelectedMassageWithFile("");
  };

  const deleteMessage = async (massage) => {
    const formData = new FormData();
    formData.append("userTimezone", userTimezone);
    formData.append("pointer_id", pointerID);
    formData.append("admin_id_note", admin_ID_FromStorage);
    formData.append("id", massage?.id);

    try {
      setLoading(true);

      const response = await deletedMassagesOfNotes(formData);

      setLoading(false);

      if (
        response &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        getAllMassagesOfNotesFun();
        toast.success(
          response?.data?.response?.success_msg || "Deleted successfully."
        );
      } else {
        console.error(
          "Error deleting data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in deleting");
        getAllMassagesOfNotesFun();
      }
    } catch (error) {
      console.error("Error deleting massage:", error);
      toast.error("Error deleting the message or file.");
      getAllMassagesOfNotesFun();
    }
  };

  const handleOptionsClick = (msgggggg) => {
    setReplyingTo(msgggggg);
    inputRef.current.focus();
  };

  const handleFileOptionsClick = (index, option) => {
    const message = messages[index];
    const fileUrl = message.documents_path;

    if (option === "download" && fileUrl) {
      window.open(fileUrl, "_blank");

      toast.success(
        "File opened in a new tab. You can download it from there."
      );
    } else {
      console.log(`File ${option} clicked for message at index: ${index}`);
    }
  };

  const toggleMessageExpand = (index) => {
    if (expandedMessages.includes(index)) {
      setExpandedMessages(expandedMessages.filter((item) => item !== index));
    } else {
      setExpandedMessages([...expandedMessages, index]);
    }
  };

  const renderMessageContent = (message, index) => {
    const maxMessageLength = 300;

    // const messageText = message?.message ?? "";

    const messageText =
      message?.reply_id !== null ||
      message?.reply_id !== "null" ||
      message?.reply_id !== ""
        ? message?.message
        : message?.reply_msg;

    // Ensure messageText is treated as a string
    const truncatedMessage = String(messageText).substring(0, maxMessageLength);

    // Function to convert URLs into clickable anchor tags
    const convertTextToLinks = (text) => {
      const urlPattern = /(https?:\/\/[^\s]+)/g; // Regex to match URLs
      return text.split(urlPattern).map((part, index) => {
        if (urlPattern.test(part)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        }
        return part;
      });
    };

    if (message.type === "text") {
      if (
        messageText.length > maxMessageLength &&
        !expandedMessages.includes(index)
      ) {
        return (
          <>
            <div>
              <div
                className="message-text"
                style={{
                  background:
                    message?.is_send_doc_request === "send"
                      ? "#E5B39B"
                      : message?.is_send_doc_request === "upload"
                      ? "#D8E4BC"
                      : "transparent",
                }}
              >
                {message?.reply_id !== "" &&
                   message?.reply_id !== "0" &&
                  message?.reply_id !== "null" &&
                  message?.reply_id !== null && (
                    <div className="reply-info">
                      <p>Replied to: {message?.reply_msg}</p>
                    </div>
                  )}
                <p>
                  {convertTextToLinks(truncatedMessage)}

                  <span className="read-more" style={{ color: "#055837" }}>
                    ...{" "}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleMessageExpand(index);
                      }}
                      className="read_more_link"
                    >
                      Read More
                    </a>
                  </span>
                </p>
                <div className="message-options">
                  {accountType !== "aqato_employee" && (
                    <>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          style={{ color: "#055837" }}
                          id={`dropdown-${index}`}
                        >
                          <i
                            style={{ color: "#055837" }}
                            className="fas fa-ellipsis-v"
                          ></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleOptionsClick(message)}
                          >
                            Reply
                          </Dropdown.Item>

                          {accountType !== "aqato_employee" && (
                            <Dropdown.Item
                              onClick={() => deleteMessage(message)}
                            >
                              Delete
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </div>
              </div>
              <div className="message-header">
                {message?.flag_img !== "" && (
                  <img
                    src={message?.flag_img}
                    alt="Preview"
                    style={{ height: "30px", width: "20px" }}
                  />
                )}

                <div className="added_by_name">{message?.user_name}</div>
                <div className="message-timestamp">
                  {formatTimestamp(new Date(message.created_at))}
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="message-text">
              {message?.reply_id !== "" &&
                 message?.reply_id !== "0" &&
                message?.reply_id !== "null" &&
                message?.reply_id !== null && (
                  <div className="reply-info">
                    <p>Replied to: {message?.reply_msg}</p>
                  </div>
                )}

              <p>{convertTextToLinks(messageText)}</p>

              <div className="message-options">
                {accountType !== "aqato_employee" && (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{ color: "#055837" }}
                        variant="link"
                        id={`dropdown-${index}`}
                      >
                        <i
                          style={{ color: "#055837" }}
                          className="fas fa-ellipsis-v"
                        ></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleOptionsClick(message)}
                        >
                          Reply
                        </Dropdown.Item>

                        {accountType !== "aqato_employee" && (
                          <Dropdown.Item onClick={() => deleteMessage(message)}>
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="read-less">
                {expandedMessages.includes(index) && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleMessageExpand(index);
                    }}
                    className="read_less_link"
                  >
                    Read Less
                  </a>
                )}
              </div>
              <div className="message-header-read-less">
                {message?.flag_img !== "" && (
                  <img
                    src={message?.flag_img}
                    alt="Preview"
                    style={{ height: "30px", width: "20px" }}
                  />
                )}

                <div className="added_by_name-read-less">
                  {message?.user_name}
                </div>

                <div className="message-timestamp">
                  {formatTimestamp(new Date(message.created_at))}
                </div>
              </div>
            </div>
          </>
        );
      }




    // } else if (
    //   message.type === "image" ||
    //   message.type === "png" ||
    //   message.type === "gif" ||
    //   message.type === "video" ||
    //   message.type === "audio" ||
    //   message.type === "application/pdf" ||
    //   message.type === "application/msword" ||
    //   message.type === "application/vnd.ms-excel" ||
    //   message.type ===
    //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
    // ) {




    } else{

      return (
        <div className="message-content">
          {message?.reply_id !== "" &&
          message?.reply_id !== "0" &&
            message?.reply_id !== "null" &&
            message?.reply_id !== null && (
              <div className="reply-info">
                <p>Replied to: 
                  
                  {/* {message?.reply_msg} */}
                  
                
                  <a
          href={message?.documents_path}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            cursor: "pointer",
            color: "#055837",
            marginLeft:"5px",
            fontWeight:"bold",
          }}
        >
          {message?.reply_msg}
        </a>
                  
        
                  
                  </p>


              </div>
            )}
          <div>
            {(message.type === "image" ||
              message.type === "png" ||
              message.type === "gif") && (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={message.documents_path}
                    alt="Preview"
                    className="file-preview"
                    style={{
                      objectFit: "cover", 
                      // width: "100%", 
                      // height: "100%", 
                    }}
                  />
                </div>

                <a
                  href={message.documents_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    display: "flex",
                    color: "#055837",
                    alignItems: "center",
                  }}
                >
                  {message.documents}
                </a>

                <p>{convertTextToLinks(messageText)}</p>

              </div>
            )}

            {message.type === "video" && (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <video
                    controls
                    src={message.documents_path}
                    className="file-preview"
                  />
                </div>

                <a
                  href={message.documents_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    display: "flex",
                    color: "#055837",
                    alignItems: "center",
                  }}
                >
                  {message.documents}
                </a>

                <p>{convertTextToLinks(messageText)}</p>

              </div>
            )}

            {message.type === "application/pdf" && (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <embed
                    src={message.documents_path}
                    type="application/pdf"
                    className="file-preview"
                  />
                </div>

                <a
                  href={message.documents_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    display: "flex",
                    color: "#055837",
                    alignItems: "center",
                  }}
                >
                  {message.documents}
                </a>

                <p>{convertTextToLinks(messageText)}</p>

              </div>
            )}

            {(message.type === "application/msword" ||
              message.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
              <div className="unsupported-file">
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ fontWeight: "bold" }}>{message?.documents}</p>
                  </div>
                </div>
                <a
                  href={message.documents_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    color: "#055837",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {message.documents}
                </a>

                <p>{convertTextToLinks(messageText)}</p>

              </div>
            )}

            {message.type === "application/zip" && (
              <div className="unsupported-file">
                <div className="file-icon">
                  <i className="fas fa-folder"></i>
                </div>
                <a
                  href={message.documents_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    color: "#055837",
                  }}
                >
                  {message.documents}
                </a>


                <p>{convertTextToLinks(messageText)}</p>

              </div>
            )}

            {message.type === "audio" && (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <audio
                    controls
                    src={message.documents_path}
                    className="file-preview"
                  />
                </div>

                <a
                  href={message.documents_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    color: "#055837",
                  }}
                >
                  {message.documents}
                </a>


                <p>{convertTextToLinks(messageText)}</p>

              </div>
            )}

            {message.type === "application/vnd.ms-excel" && (
              <div className="unsupported-file">
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ fontWeight: "bold" }}>{message?.documents}</p>
                  </div>
                </div>
                <a
                  href={message.documents_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    color: "#055837",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {message.documents}
                </a>

                <p>{convertTextToLinks(messageText)}</p>

              </div>
            )}

            {!allowedFileTypes.includes(message.type) && (
              <div
                style={{ color: "#055837" }}
                className="unsupported-file"
              ></div>
            )}
          </div>
          <div className="file-options">
            <Dropdown>
              <Dropdown.Toggle
                style={{ color: "#055837" }}
                variant="link"
                id={`file-dropdown-${index}`}
              >
                <i
                  style={{ color: "#055837" }}
                  className="fas fa-ellipsis-v"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  // onClick={() => handleFileOptionsClick(index, "reply")}
                  onClick={() => handleOptionsClick(message)}
                >
                  Reply
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => handleFileOptionsClick(index, "download")}
                >
                  Download
                </Dropdown.Item>
                {accountType !== "aqato_employee" && (
                  <Dropdown.Item onClick={() => deleteMessage(message)}>
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="message-header">
            {message?.flag_img !== "" && (
              <img
                src={message?.flag_img}
                alt="Preview"
                style={{ height: "30px", width: "20px" }}
              />
            )}

            <div className="added_by_name">{message?.user_name}</div>
            <div className="message-timestamp">
              {formatTimestamp(new Date(message.created_at))}
            </div>
          </div>
        </div>
      );
    }
  };

  const formatTimestamp = (timestamp) => {
    return `${timestamp.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    })} ${timestamp.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  };

  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/bmp",
    "image/tiff",
    "image/webp",
    "image/svg+xml",
    "video/mp4",
    "video/mpeg",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/quicktime",
    "video/webm",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/zip",
    "application/x-zip-compressed",
    "application/x-rar-compressed",
    "audio/mpeg",
    "audio/wav",
    "audio/ogg",
    "audio/aac",
    "application/rar",
    "application/x-7z-compressed",
    "application/x-tar",
    "application/x-cpio",
    "application/x-shockwave-flash",
    "application/x-msdownload",
    "application/x-msdos-program",
    "text/plain",
    "text/html",
    "text/css",
    "text/javascript",
    "text/xml",
    "text/csv",
    "text/markdown",
  ];

  const showToast = (message) => {
    toast.warn(message);
    inputRef.current.focus();
  };

  const filePreviewBoxStyles = {
    width: "100%",
    height: "200px",
    // objectFit: "contain",
    objectFit: "cover",

  };

  return (
    <Card className="notes-chat-card">
      <Card.Body>
        <Container className="notes-chat-container">
          <div className="message-list">
            {messages.map(
              (message, index) =>
                message.isdeleted !== "1" && (
                  <div
                    key={index}
                    className={`message ${
                      message.type === "file" ? "sent-file" : ""
                    }`}
                  >
                    <div className="message-content">
                      {renderMessageContent(message, index)}
                    </div>
                  </div>
                )
            )}

            <div ref={messagesEndRef} />
          </div>

          <Row className="input-row">
            {replyingTo && (
              <>
                <p style={{ display: "flex", alignItems: "center" }}>
                  {replyingTo && (
                    <>
                      <a
                        onClick={() => setReplyingTo(null)}
                        style={{
                          cursor: "pointer",
                          marginLeft: "8px",
                          marginRight: "15px",
                          color: "black",
                        }}
                        aria-label="Close"
                      >
                        <i className="fas fa-times"></i>
                      </a>



                      <strong style={{ marginRight: "8px" }}>
                        Replying to :
                      </strong>{" "}



{/* {replyingTo?.message} */}

 {replyingTo?.type === "text" ? (
        <span>{replyingTo?.message}</span>
      ) : (
        <a
          href={replyingTo?.documents_path}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            cursor: "pointer",
            color: "#055837",
          }}
        >
          {replyingTo?.documents}
        </a>
      )}


                    </>
                  )}
                </p>
              </>
            )}

            <Col xs={10}>
              <Form.Control
                as="textarea"
                rows={1}
                style={{
                  height: "auto",
                  minHeight: "45px",
                  maxHeight: "150px",
                }}
                placeholder={
                  replyingTo ? `Write a reply ..` : "Write a note .."
                }
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
                ref={inputRef}
              />
            </Col>

{/* 
{!replyingTo && (
            <Col xs={1} className="file-upload-icon">
              <Form.Control
                type="file"
                id="file-upload"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <i className="fas fa-paperclip"></i>
              </label>
            </Col>
)} 
 */}




<Col xs={1} className="file-upload-icon">
              <Form.Control
                type="file"
                id="file-upload"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <i className="fas fa-paperclip"></i>
              </label>
            </Col>









            <Col xs={1}>
              <Button
                variant="primary"
                onClick={handleSend}
                className="send-button"
              >
                <i className="fas fa-paper-plane"></i>
              </Button>
            </Col>
          </Row>

          {selectedFile && (
            <Row className="file-preview-box">
              <Col xs={12}>
                <div className="file-preview-content">
                  <p className="file-name" style={{ fontWeight: "bold" }}>
                    {selectedFile?.name}
                  </p>

                  {/* Check if the file type is allowed or a known type */}
                  {allowedFileTypes.includes(selectedFile.type) ||
                  allowedFileTypes.includes(selectedFile.type.split("/")[0]) ? (
                    <>
                      {/* Display preview based on file type */}
                      {(selectedFile.type === "image/png" ||
                        selectedFile.type === "image/jpeg" ||
                        selectedFile.type === "image/jpg" ||
                        selectedFile.type.match(
                          /^image\/(png|jpeg|jpg|gif|bmp|svg\+xml|webp)$/
                        )) && (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Preview"
                          className="file-preview-image"
                          style={filePreviewBoxStyles}
                        />
                      )}
                      {selectedFile.type === "video/mp4" && (
                        <video
                          controls
                          src={URL.createObjectURL(selectedFile)}
                          className="file-preview-video"
                          style={filePreviewBoxStyles}
                        />
                      )}
                      {selectedFile.type === "application/pdf" && (
                        <embed
                          src={URL.createObjectURL(selectedFile)}
                          type="application/pdf"
                          className="file-preview-pdf"
                          style={filePreviewBoxStyles}
                        />
                      )}
                      {selectedFile.type === "application/zip" && (
                        <div className="file-icon">
                          <i className="fas fa-folder"></i>
                        </div>
                      )}
                      {selectedFile.type === "audio/mpeg" && (
                        <audio
                          controls
                          src={URL.createObjectURL(selectedFile)}
                          className="file-preview-audio"
                        />
                      )}
                      {(selectedFile.type === "application/vnd.ms-excel" ||
                        selectedFile.type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        selectedFile.type === "application/msword" ||
                        selectedFile.type ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && ( // For .docx
                        <div className="centered-link">
                          <a
                            href={URL.createObjectURL(selectedFile)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            (Click to view)
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="centered-link"></div>
                  )}

                  {/* Actions */}
                  <div className="file-preview-actions">
                    <Button
                      variant="link"
                      className="file-cancel-button"
                      onClick={() => handleCancelFile(selectedFile)}
                      style={{
                        border: "none",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                    {selectedFile && (
                      <Button
                        variant="link"
                        className="file-send-button"
                        onClick={handleSendFile}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleSendFile();
                          }
                        }}
                        style={{
                          border: "none",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <i className="fas fa-paper-plane"></i>
                      </Button>
                    )}
                  </div>

                  <input
                    type="text"
                    className="form-data"
                    placeholder="Type your message..."
                    value={selectedMassageWithFile}
                    onChange={(e) => setSelectedMassageWithFile(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #ced4da",
                      borderRadius: "5px",
                    }}
                  />



                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Card.Body>
      <ToastContainer position="top-right" />
    </Card>
  );
};

export default NotesChat;
