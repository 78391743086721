import React, { useEffect, useRef } from "react";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.css";
import "./TomCustomCss.css";

const TomSelectComponent = ({
  options,  // Options passed as prop, which will be used to create the dropdown list
  onChange,  // onChange function passed to handle value change
  placeholder = "Select an option",  // Default placeholder text
  value,  // The selected value passed as prop
  plugins = []  // Optional plugins passed for TomSelect
}) => {
  const selectRef = useRef(null);  // Reference to the select element to initialize TomSelect

  useEffect(() => {
    // Initialize TomSelect when component is mounted
    const tomSelectInstance = new TomSelect(selectRef.current, {
      options: options.map((option) => ({
        value: option.value,  // Value for the option
        text: option.label  // Text to display in the dropdown
      })),
      onChange: onChange,  // Callback for when the value changes
      placeholder: placeholder,  // Placeholder for the dropdown
      plugins: plugins,  // Apply plugins passed to TomSelect
    });

    // If a value is passed in, set it as the selected value in TomSelect
    if (value) {
      tomSelectInstance.setValue(value);
    }

    // Cleanup TomSelect instance when component is unmounted
    return () => {
      tomSelectInstance.destroy();
    };
  }, [options, onChange, placeholder, value, plugins]);  // Dependency array to re-run the effect when props change

  return (
    // <select> element that TomSelect will enhance
    <select ref={selectRef} defaultValue={value}>
      <option value="">{placeholder}</option>  {/* This will be the placeholder option */}
    </select>
  );
};

export default TomSelectComponent;