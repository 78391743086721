import React, { useState, useEffect } from "react";
import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import Agent from "./Agent/Agent";
import Applicant from "./Applicant/Applicant";

const Applicant_Agent = () => {
  const [AgentTabActive, setAgentTabActive] = useState(false);
  const [ApplicantTabActive, setApplicantTabActive] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(`applicant_agent_agent_tab_active`) === "true") {
      setAgentTabActive(true);
      setApplicantTabActive(false);
    } else if (
      localStorage.getItem(`applicant_agent_applicant_tab_active`) === "true"
    ) {
      setApplicantTabActive(true);
      setAgentTabActive(false);
    } else {
      setAgentTabActive(true);
      setApplicantTabActive(false);
    }
  }, []);

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Applicant Agent
              </h3>
            </div>

            <div className="card card-primary card-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    style={{ marginTop: "0px", cursor: "pointer" }}
                  >
                    <a
                      className={`nav-link nav_inactive_tab_btn ${
                        AgentTabActive ? "nav_active_tab_btn" : ""
                      }`}
                      id="tabs_view_edit"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_view_edit"
                      aria-selected={AgentTabActive ? "true" : "false"}
                      onClick={() => {
                        setAgentTabActive(true);
                        setApplicantTabActive(false);

                        localStorage.setItem(
                          `applicant_agent_agent_tab_active`,
                          "true"
                        );
                        localStorage.setItem(
                          `applicant_agent_applicant_tab_active`,
                          "false"
                        );
                      }}
                    >
                      <h6 className="h6">Agent</h6>
                    </a>
                  </li>
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={`nav-link nav_inactive_tab_btn ${
                        ApplicantTabActive ? "nav_active_tab_btn" : ""
                      }`}
                      id="tabs_documents"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_documents"
                      aria-selected={ApplicantTabActive ? "true" : "false"}
                      onClick={() => {
                        setApplicantTabActive(true);
                        setAgentTabActive(false);

                        localStorage.setItem(
                          `applicant_agent_agent_tab_active`,
                          "false"
                        );
                        localStorage.setItem(
                          `applicant_agent_applicant_tab_active`,
                          "true"
                        );
                      }}
                    >
                      <h6 className="h6">Applicant</h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {AgentTabActive && <Agent />}
            {ApplicantTabActive && <Applicant />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicant_Agent;
